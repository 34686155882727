import React from "react";
import { Avatar, Col, Form, Modal, Row, Space } from "antd";
import {
  CommonButton,
  CommonTextAreaField,
  CommonTextField,
} from "../../../common";
import { Images } from "../../../../theme";
import "./styles.scss";
import {
  inputFieldRule,
  replaceValInString,
  toastAlert,
} from "../../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { ASK_QUESTION_ROUTE } from "../../../../constants";

const SellerSubmissionModal = ({ handleClose, preview, handleConfirm }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={633}
      className="seller-sub-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <Space size={15} style={{ marginBottom: "30px" }}>
            <Avatar size={51} src={<Images.UserAvatar />} />
            <Space size={6} direction="vertical">
              <CommonTextField
                fontFamily={"seventyTwoRegular"}
                className={""}
                fontSize={"14px"}
                text="Ronald Richards"
                lineHeight={"10px"}
              />
              <CommonTextField
                fontFamily={"seventyTwoRegular"}
                fontSize={"12px"}
                className={"light-text"}
                text="Senior UI UX Designer"
              />
            </Space>
          </Space>
          <div className="bid-download c-p" style={{ marginBottom: "40px" }}>
            <CommonTextField text={"Bid.pdf"} fontFamily={"seventyTwoBold"} />
            <Images.DownloadIcon />
          </div>
          <div className="modal-footer">
            <CommonButton
              text={"Ask More Questions"}
              borderRadius={"12px"}
              width={"200px"}
              height={"56px"}
              background={"rgba(3, 25, 70, 1)"}
              onClick={() =>
                navigate(
                  replaceValInString(ASK_QUESTION_ROUTE, {
                    ":id": id,
                    ":subId": 1,
                  })
                )
              }
            />
            <CommonButton
              text={"Confirm Bid"}
              borderRadius={"12px"}
              width={"200px"}
              height={"56px"}
              onClick={() => {
                toastAlert("Bid confirmed successfully.");
                handleClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SellerSubmissionModal;
