import { Col, Row } from "antd";
import React from "react";
import { CommonButton, CommonHeading, CommonTextField } from "../../../common";
import { WHY_CENTRAl_BID_CONTENT } from "../../../../constants";
import { Images } from "../../../../theme";

const BidOpportunity = () => {
  return (
    <Row gutter={[40, 40]} className="why-Central-parent">
      <Col md={24} lg={12} className="right-side">
        <img src={Images.bidOpportunity} />
      </Col>
      <Col md={24} lg={12} className="left-side">
        <CommonHeading color={"#021349"} text="Bid opportunities" />
        <CommonTextField
          color="#465E7D"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
        />
        <CommonTextField
          mt={"20px"}
          color="#465E7D"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
        />
        <CommonButton text={"Get Started"} />
      </Col>
    </Row>
  );
};

export default BidOpportunity;
