import React, { useState } from "react";
import {
  CommonButton,
  CommonModal,
  CommonTextField,
  DocUploader,
} from "../../../common";
import "./styles.scss";
import { Images } from "../../../../theme";
import { Col, Row } from "antd";
import { replaceValInString, toastAlert } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { EDIT_BID_ROUTE } from "../../../../constants";
import InquiryModal from "../inquiryModal";

const yourBid = ({ ongoing, isCancelled }) => {
  //STATES
  const [inquiryView, setInquiryView] = useState(false);
  const [confirmationModalPreview, setConfirmationModalPreview] =
    useState(false);
  const [inquiryModalPreview, setInquiryModalPreview] = useState(false);
  const [selected, setSelected] = useState(null);

  //CONST VALS
  const navigate = useNavigate();

  //HANDLERS
  const inquiryModalPreviewHandler = () => {
    setInquiryModalPreview(!inquiryModalPreview);
  };

  return (
    <div>
      <CommonTextField
        text={"Your Bid"}
        fontFamily={"seventyTwoSemiBold"}
        fontSize={"24px"}
        lineHeight={"36px"}
      />
      <div className="bid-download">
        <CommonTextField text={"Bid.pdf"} fontFamily={"seventyTwoBold"} />
        <Images.DownloadIcon />
      </div>
      {isCancelled && (
        <>
          <CommonTextField
            text={"Cancellation Reason"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mt={"30px"}
          />
          <CommonTextField
            text={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </>
      )}
      {ongoing && (
        <div className="ongoing-option-wrapper">
          <div className="buttonWrapper">
            {!inquiryView && (
              <CommonButton
                text={"Ask more Questions"}
                background={"rgba(3, 25, 70, 1)"}
                fontSize={"16px"}
                classname={"buttons"}
                fontFamily={"seventyTwoBold"}
                onClick={() => setInquiryView(true)}
              />
            )}
            <CommonButton
              text={"Withdraw Bid"}
              fontSize={"18px"}
              classname={"buttons"}
              fontFamily={"seventyTwoBold"}
              onClick={() => setConfirmationModalPreview(true)}
            />
            <div
              className="edit-icon c-p"
              onClick={() =>
                navigate(replaceValInString(EDIT_BID_ROUTE, { ":id": 1 }))
              }
            >
              <Images.EditIcon />
            </div>
          </div>
          {inquiryView && (
            <div className="questions-wrapper">
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <CommonTextField
                    fontFamily={"seventyTwoBold"}
                    fontSize={"20px"}
                    text={"Ask Question 4/5"}
                    color={"#021349"}
                  />
                </Col>
                {[1, 2, 3].map((item, index) => (
                  <Col span={24} key={index}>
                    <div
                      className={`question-item ${
                        selected === index ? "selected" : ""
                      }`}
                      onClick={() => setSelected(index)}
                    >
                      <CommonTextField
                        fontFamily={"seventyTwoLight"}
                        fontSize={"16px"}
                        text={
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore"
                        }
                        color={"#021349"}
                      />
                    </div>
                  </Col>
                ))}
                <Col span={24}></Col>
              </Row>
              <div className="ask-btn-wrapper">
                <CommonButton
                  text={"Type an inquiry"}
                  background={"#031946"}
                  width={"200px"}
                  height={"56px"}
                  onClick={inquiryModalPreviewHandler}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <CommonModal
        isModalVisible={confirmationModalPreview}
        setIsModalVisible={setConfirmationModalPreview}
        discription="Do you want to withdraw your bid?"
        onConfirm={() => {
          setConfirmationModalPreview(false);
          toastAlert("Bid withdrew successfully.");
          navigate(-1);
        }}
      ></CommonModal>
      <InquiryModal
        preview={inquiryModalPreview}
        handleClose={inquiryModalPreviewHandler}
        handleConfirm={() => {
          toastAlert("Inquiry submitted successfully.");
          inquiryModalPreviewHandler();
        }}
      />
    </div>
  );
};

export default yourBid;
