import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  ACCESS_TYPES,
  ACCOUNT_VERIFICATION_ROUTE,
  ASK_QUESTION_ROUTE,
  AUTH_SUBSCRIPTION_ROUTE,
  BIDS_DETAIL,
  BIDS_ROUTE,
  CREATE_BID_ROUTE,
  DASHBOARD_ROUTE,
  EDIT_BID_ROUTE,
  EMAIL_VERIFICATION_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  MYBIdS_ROUTE,
  OPEN_BIDS_DETAIL,
  PROFILE_ROUTE,
  RESET_PASSWORD_ROUTE,
  SIGN_UP_ROUTE,
  STEPS_DETAIL,
  SUBSCRIPTION_ROUTE,
} from "../constants";
import Helmet from "react-helmet";
import {
  PrivateRoute,
  PublicRoute,
  AuthRoute,
} from "../config/routes-handling";
import {
  AccountVerification,
  AskQuestion,
  AuthSubscriptions,
  BidDetails,
  CreateBid,
  Dashboard,
  EmailVerification,
  Error,
  ForgotPassword,
  Home,
  Login,
  MyBids,
  OpenBids,
  Profile,
  ResetPassword,
  Signup,
  StepsDetail,
  Subscription,
} from "../modules";

const renderRouteSharedLayout = (title, description, access, component) => (
  <React.Fragment>
    <Helmet>
      <title>
        {title ? `${title} |` : ""} {process.env.REACT_APP_WEB_TITLE}
      </title>
      {description && <meta name="description" content={description} />}
    </Helmet>
    {access === ACCESS_TYPES.AUTH ? (
      <AuthRoute> {component}</AuthRoute>
    ) : access === ACCESS_TYPES.PRIVATE ? (
      <PrivateRoute>{component}</PrivateRoute>
    ) : (
      <PublicRoute>{component}</PublicRoute>
    )}
  </React.Fragment>
);

const PageRoutes = () => {
  // CONST VALS
  const location = useLocation();

  const ROUTES = [
    // PUBLIC ROUTES

    {
      route: HOME_ROUTE,
      title: "Home",
      description: "",
      access: ACCESS_TYPES.PUBLIC,
      component: <Home />,
    },

    // AUTH ROUTES
    {
      route: LOGIN_ROUTE,
      title: "Login",
      description: "",
      access: ACCESS_TYPES.AUTH,
      component: <Login />,
    },
    {
      route: SIGN_UP_ROUTE,
      title: "Sign up",
      description: "",
      access: ACCESS_TYPES.AUTH,
      component: <Signup />,
    },
    {
      route: AUTH_SUBSCRIPTION_ROUTE,
      title: "Subscriptions",
      description: "",
      access: ACCESS_TYPES.AUTH,
      component: <AuthSubscriptions />,
    },
    {
      route: FORGOT_PASSWORD_ROUTE,
      title: "Forgot Password",
      description: "",
      access: ACCESS_TYPES.AUTH,
      component: <ForgotPassword />,
    },
    {
      route: RESET_PASSWORD_ROUTE,
      title: "Reset Password",
      description: "",
      access: ACCESS_TYPES.AUTH,
      component: <ResetPassword />,
    },
    {
      route: EMAIL_VERIFICATION_ROUTE,
      title: "Email Verification",
      description: "",
      access: ACCESS_TYPES.AUTH,
      component: <EmailVerification />,
    },
    {
      route: ACCOUNT_VERIFICATION_ROUTE,
      title: "Account Verification",
      description: "",
      access: ACCESS_TYPES.AUTH,
      component: <AccountVerification />,
    },
    // PRIVATE ROUTE
    {
      route: DASHBOARD_ROUTE,
      title: "Dashboard",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <Dashboard />,
    },
    {
      route: MYBIdS_ROUTE,
      title: "My bids",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <MyBids />,
    },
    {
      route: BIDS_ROUTE,
      title: "Bids",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <OpenBids />,
    },
    {
      route: SUBSCRIPTION_ROUTE,
      title: "Subscription",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <Subscription />,
    },
    {
      route: PROFILE_ROUTE,
      title: "My bids",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <Profile />,
    },
    {
      route: BIDS_DETAIL,
      title: "My bids",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <BidDetails />,
    },
    {
      route: OPEN_BIDS_DETAIL,
      title: "My bids",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <BidDetails />,
    },
    {
      route: STEPS_DETAIL,
      title: "My bids",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <StepsDetail />,
    },
    {
      route: CREATE_BID_ROUTE,
      title: "Create Bid",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <CreateBid />,
    },
    {
      route: EDIT_BID_ROUTE,
      title: "Edit Bid",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <CreateBid />,
    },
    {
      route: ASK_QUESTION_ROUTE,
      title: "Ask Questions",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <AskQuestion />,
    },
  ];

  // HOOKS
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        {ROUTES.map((item, index) => (
          <Route
            path={item.route}
            element={renderRouteSharedLayout(
              item.title,
              item.description,
              item.access,
              item.component
            )}
            key={index}
          />
        ))}
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default PageRoutes;
