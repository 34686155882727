import React from "react";
import { Images } from "../../../theme";
import { CommonButton } from "../../common";
import "./styles.scss";

const PublicHeader = () => {
  return (
    <div className="public-header">
      <Images.Mainlogo />
      <div className="left-menu">
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Contact us</li>
        </ul>
        <CommonButton text={"Account"} width={"150px"} />
      </div>
    </div>
  );
};

export default PublicHeader;
