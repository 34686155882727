import { Carousel, Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { AuthCarousel } from "../../components";
import {
  CommonButton,
  CommonInputField,
  CommonPasswordInput,
  CommonTextField,
} from "../../components/common";
import {
  AUTH_SUBSCRIPTION_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
} from "../../constants";
import { CustomDispatch } from "../../helpers";
import { loginSuccess } from "../../redux/slicers/user";
import { Images } from "../../theme";
import { inputFieldRule, isPasswordValid } from "../../utils";
import "./auth.scss";

const AccountVerification = () => {
  //STATES
  const [_otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [enableResendBtn, setEnableResendBtn] = useState(false);

  //CONST VALS
  const [form] = Form.useForm();
  const navigate = useNavigate();
  //CUSTOM DISPATCH

  //HANDLERS
  const handleLogin = (vals) => {
    console.log(vals);
    navigate(AUTH_SUBSCRIPTION_ROUTE);
  };

  const handleResendCode = () => {
    setSeconds(60);
    setEnableResendBtn(false);
  };

  // HOOKS
  useEffect(() => {
    if (seconds === 0) return setEnableResendBtn(true);
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div className="auth-box">
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-form-wrapper">
            <CommonTextField
              text={"Authenticate Your Account"}
              fontSize="39px"
              fontWeight={"600"}
              color="#031946"
              mb={"18px"}
              whiteSpace="nowrap"
            />
            <CommonTextField
              text={"We have sent an OTP to your email"}
              fontSize="14px"
              fontWeight={"400"}
              color="#465E7D"
              mb={"30px"}
            />
            <span className="wrong-acc-text">
              jo*****@domain.com{" "}
              <Link to={FORGOT_PASSWORD_ROUTE}>Not You?</Link>
            </span>

            <Form form={form} className="login-form" onFinish={handleLogin}>
              <Form.Item
                rules={inputFieldRule({
                  name: "Otp",
                  isWhiteSpace: false,
                  isMin: true,
                })}
                name="otp"
              >
                <OTPInput
                  inputType="number"
                  shouldAutoFocus={true}
                  value={_otp}
                  containerStyle={`otpCodeInput custom-otp-input`}
                  onChange={(e) => setOtp(e)}
                  numInputs={6}
                  renderSeparator={<span>&emsp;</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </Form.Item>
              <span className="resend-timer">
                00:{`${seconds}`.padStart(2, 0)}
              </span>

              <CommonButton
                text={"Verify"}
                fontSize="18px"
                fontWeight={"600"}
                height="56px"
                htmlType="submit"
                marginBottom={"15px"}
                marginTop={"15px"}
              />
              <CommonButton
                text={"Resend Code"}
                fontSize="18px"
                fontWeight={"600"}
                height="56px"
                background={"#031946"}
                onClick={handleResendCode}
                disabled={!enableResendBtn}
              />
            </Form>
          </div>
        </div>
      </div>
      <div className="auth-image-wrapper">
        <img src={Images.AccountVerification} alt="Login Banner" />
        <AuthCarousel />
      </div>
    </div>
  );
};
export default AccountVerification;
