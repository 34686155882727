import { Carousel, Form } from "antd";
import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthCarousel, ImageUploader } from "../../components";
import {
  CommonButton,
  CommonInputField,
  CommonPasswordInput,
  CommonTextAreaField,
  CommonTextField,
} from "../../components/common";
import {
  ACCOUNT_VERIFICATION_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
} from "../../constants";
import { CustomDispatch } from "../../helpers";
import { loginSuccess, setSginupRoleRequest } from "../../redux/slicers/user";
import { Images } from "../../theme";
import {
  creditCardValidator,
  inputFieldRule,
  isPasswordValid,
} from "../../utils";
import "./auth.scss";

const Signup = () => {
  //STATES
  const [selectedOption, setSelectedOption] = useState("buyer");
  const [step, setStep] = useState(1);
  //CONST VALS
  const [form] = Form.useForm();
  const [orgForm] = Form.useForm();
  const navigate = useNavigate();

  //CUSTOM DISPATCH
  const [setSignupRole] = CustomDispatch(setSginupRoleRequest);

  //HANDLERS
  const handleSignup = (vals) => {
    console.log(vals);
    const isSeller = selectedOption === "seller";
    if (isSeller) {
      setSignupRole({ payload: { isSeller } });
      navigate(ACCOUNT_VERIFICATION_ROUTE);
    } else {
      setStep(2);
    }
  };
  const handleOrganizationSignup = (vals) => {
    const isSeller = selectedOption === "seller";
    setSignupRole({ payload: { isSeller } });
    navigate(ACCOUNT_VERIFICATION_ROUTE);
  };
  return (
    <div className="auth-box" style={{ alignItems: "flex-start" }}>
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-form-wrapper">
            <CommonTextField
              text={"Signup"}
              fontSize="39px"
              fontWeight={"600"}
              color="#031946"
              mb={"12px"}
              whiteSpace="nowrap"
            />
            <CommonTextField
              text={
                "We've missed you! Please Signup to catch up on what you've missed"
              }
              fontSize="14px"
              fontWeight={"400"}
              color="#465E7D"
              mb={"30px"}
            />
            {step === 1 ? (
              <Form form={form} className="login-form" onFinish={handleSignup}>
                <CommonInputField
                  placeholder={"Enter your username"}
                  prefix={<Images.UsernameIcon />}
                  rules={inputFieldRule({
                    name: "Username",
                    isMax: true,
                    max: 30,
                  })}
                  name="username"
                />
                <CommonInputField
                  placeholder={"Enter first name"}
                  prefix={<Images.UsernameIcon />}
                  rules={inputFieldRule({
                    name: "First name",
                    isMax: true,
                    max: 30,
                  })}
                  name="firstName"
                />
                <CommonInputField
                  placeholder={"Enter last name"}
                  prefix={<Images.UsernameIcon />}
                  rules={inputFieldRule({
                    name: "Last name",
                    isMax: true,
                    max: 30,
                  })}
                  name="lastName"
                />
                <CommonInputField
                  placeholder={"Enter your email"}
                  prefix={<Images.EmailIcon />}
                  rules={inputFieldRule({
                    name: "Email",
                    isEmail: true,
                    isMax: true,
                    max: 100,
                  })}
                  name="email"
                />
                <CommonInputField
                  placeholder={"Enter phone number"}
                  prefix={<Images.PhoneIcon />}
                  rules={inputFieldRule({
                    name: "Phone",
                    isMax: true,
                    max: 20,
                  })}
                  name="phone"
                  onKeyDown={(e) => creditCardValidator(e, "card", 20)}
                />
                <CommonPasswordInput
                  placeholder={"Enter your password"}
                  prefix={<Images.PasswordIcon />}
                  name="password"
                  rules={[
                    ...inputFieldRule({
                      name: "Password",
                      isWhiteSpace: false,
                    }),
                    () => ({
                      validator(_, value) {
                        if (!value || isPasswordValid(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password must contain 8 characters including 1 small letter, 1 capital letter, 1 digit and 1 special character!"
                          )
                        );
                      },
                    }),
                  ]}
                />
                <CommonPasswordInput
                  placeholder={"Re-enter your password"}
                  prefix={<Images.PasswordIcon />}
                  name="rePassword"
                  rules={[
                    ...inputFieldRule({
                      name: "Confirm password",
                      isWhiteSpace: false,
                    }),
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The password you entered do not match")
                        );
                      },
                    }),
                  ]}
                />
                <div className="signup-option-wrapper">
                  <div
                    className={`signup-option ${
                      selectedOption === "buyer" ? "" : "active"
                    }`}
                    onClick={() => setSelectedOption("seller")}
                  >
                    Seller
                  </div>
                  <div
                    className={`signup-option ${
                      selectedOption === "buyer" ? "active" : ""
                    }`}
                    onClick={() => setSelectedOption("buyer")}
                  >
                    Buyer
                  </div>
                </div>
                <CommonButton
                  text={"Sign Up"}
                  fontSize="18px"
                  fontWeight={"400"}
                  height="56px"
                  htmlType="submit"
                />
              </Form>
            ) : (
              <>
                <ImageUploader />
                <Form
                  form={orgForm}
                  className="login-form"
                  onFinish={handleOrganizationSignup}
                >
                  <CommonInputField
                    placeholder={"Enter your organization name"}
                    prefix={<Images.Organization />}
                    rules={inputFieldRule({
                      name: "Organization name",
                      isMax: true,
                      max: 30,
                    })}
                    name="orgName"
                  />
                  <CommonInputField
                    placeholder={"Enter organization type"}
                    prefix={<Images.Organization />}
                    rules={inputFieldRule({
                      name: "Organization type",
                      isMax: true,
                      max: 30,
                    })}
                    name="orgType"
                  />
                  <CommonInputField
                    placeholder={"Industry or sector"}
                    prefix={<Images.Industry />}
                    rules={inputFieldRule({
                      name: "Industry/Sector",
                      isMax: true,
                      max: 30,
                    })}
                    name="industry"
                  />
                  <CommonInputField
                    placeholder={"Enter your address"}
                    prefix={<Images.Location />}
                    rules={inputFieldRule({
                      name: "Address",
                      isMax: true,
                      max: 30,
                    })}
                    name="address"
                  />
                  <CommonInputField
                    placeholder={"Additional contact information"}
                    prefix={<Images.PhoneIcon />}
                    rules={inputFieldRule({
                      name: "Additional contact info",
                      isMax: true,
                      max: 20,
                    })}
                    name="addPhone"
                    onKeyDown={(e) => creditCardValidator(e, "card", 20)}
                  />
                  <CommonTextAreaField
                    placeholder={"Description"}
                    height="148px"
                  />
                  <CommonButton
                    text={"Continue"}
                    fontSize="18px"
                    fontWeight={"400"}
                    height="56px"
                    htmlType="submit"
                  />
                </Form>
              </>
            )}
          </div>
          {step === 1 && (
            <span className="signup-prompt" style={{ marginTop: "70px" }}>
              Already have an account? <Link to={LOGIN_ROUTE}>Login</Link>
            </span>
          )}
        </div>
      </div>
      <div
        className="auth-image-wrapper"
        style={{
          height: "100%",
          position: "sticky",
          top: "0px",
          backgroundImage: `url(${
            step === 1 ? Images.SignupImage1 : Images.SignupImage2
          })`,
        }}
      >
        {/* <div
          className="bgImage"
          style={{
            minHeight: "100%",
            backgroundImage: `url(${Images.SignupImage1})`,
          }}
        ></div> */}
        {/* <img
          style={{ minHeight: "100%" }}
          src={step === 1 ? Images.SignupImage1 : Images.SignupImage2}
          alt="Login Banner"
        /> */}
        <AuthCarousel />
      </div>
    </div>
  );
};
export default Signup;
