import React, { useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonTextField,
  DocUploader,
} from "../../../../../../components/common";
import { Col, Row } from "antd";

function Step3({ isHidden, handleNextStep }) {
  return (
    <div className={` ${isHidden ? "hidden" : ""} `}>
      <Row gutter={[23, 23]}>
        <Col span={24}>
          <CommonTextField
            text={"Maximum file upload size is 500 MB"}
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            fontFamily={"seventyTwoRegular"}
            mt={"30px"}
          />
        </Col>

        <Col xs={24} lg={12}>
          <DocUploader
            isCustom
            customCode={
              <CommonTextField
                text={"Submit 1 Proposal"}
                fontFamily={"seventyTwoSemiBold"}
                fontSize={"16px"}
                lineHeight={"18.4px"}
              />
            }
            docType="Doc"
            maxSize={500}
            multiple={false}
          />
        </Col>

        <Col xs={24} lg={12}>
          <DocUploader
            isCustom
            customCode={
              <CommonTextField
                text={"Submitter 2 - Appendix C 0 Submission Form (Completed)"}
                fontFamily={"seventyTwoSemiBold"}
                fontSize={"16px"}
                lineHeight={"18.4px"}
              />
            }
            docType="Doc"
            maxSize={500}
            multiple={false}
          />{" "}
        </Col>

        <Col xs={24} lg={12}>
          <DocUploader
            isCustom
            customCode={
              <CommonTextField
                text={
                  "Submittal 3 - Health & Safety Questionnaire ( Completed)"
                }
                fontFamily={"seventyTwoSemiBold"}
                fontSize={"16px"}
                lineHeight={"18.4px"}
              />
            }
            docType="Doc"
            maxSize={500}
            multiple={false}
          />{" "}
        </Col>

        <Col xs={24} lg={12}>
          <DocUploader
            isCustom
            customCode={
              <CommonTextField
                text={
                  "Submitter 4 - Other ( additional information as required)"
                }
                fontFamily={"seventyTwoSemiBold"}
                fontSize={"16px"}
                lineHeight={"18.4px"}
              />
            }
            docType="Doc"
            maxSize={500}
            multiple={false}
          />{" "}
        </Col>
      </Row>

      <div className="buttonWrapper">
        <CommonButton
          text={"Continue"}
          classname={"button"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={() => handleNextStep(4)}
        />
      </div>
    </div>
  );
}

export default Step3;
