import React, { useState } from "react";
import "./styles.scss";
import {
  CommonButton,
  CommonHeading,
  CommonModal,
  CommonTextField,
} from "../../../components/common";
import { Avatar, Col, Row, Space } from "antd";
import { Images } from "../../../theme";
import { BidsTable, CardsSection, InquiryModal } from "../../../components";
import { toastAlert } from "../../../utils";
const AskQuestion = () => {
  const [selected, setSelected] = useState(null);
  const [inquiry, setInquiry] = useState(false);

  const inquiryModalPreviewHandler = () => {
    setInquiry(!inquiry);
  };

  const handleInquirySubmit = () => {
    toastAlert("Inquiry submited successfully.");
    inquiryModalPreviewHandler();
  };

  return (
    <section className="ask-question-wrapper">
      <Space size={15} style={{ marginBottom: "30px" }}>
        <Avatar size={51} src={<Images.UserAvatar />} />
        <Space size={6} direction="vertical">
          <CommonTextField
            fontFamily={"seventyTwoRegular"}
            className={""}
            fontSize={"14px"}
            text="Ronald Richards"
            lineHeight={"10px"}
          />
          <CommonTextField
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            className={"light-text"}
            text="Senior UI UX Designer"
          />
        </Space>
      </Space>
      <div className="bid-download c-p" style={{ marginBottom: "30px" }}>
        <CommonTextField text={"Bid.pdf"} fontFamily={"seventyTwoBold"} />
        <Images.DownloadIcon />
      </div>
      <div className="questions-wrapper">
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <CommonTextField
              fontFamily={"seventyTwoBold"}
              fontSize={"20px"}
              text={"Ask Question 4/5"}
              color={"#021349"}
            />
          </Col>
          {[1, 2, 3].map((item, index) => (
            <Col span={24} key={index}>
              <div
                className={`question-item ${
                  selected === index ? "selected" : ""
                }`}
                onClick={() => setSelected(index)}
              >
                <CommonTextField
                  fontFamily={"seventyTwoLight"}
                  fontSize={"16px"}
                  text={
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore"
                  }
                  color={"#021349"}
                />
              </div>
            </Col>
          ))}
        </Row>
        <div className="ask-btn-wrapper">
          <CommonButton
            onClick={() => setInquiry(true)}
            text={"Type an inquiry"}
            background={"#031946"}
            width={"200px"}
            height={"56px"}
          />
        </div>
      </div>
      <InquiryModal
        preview={inquiry}
        handleClose={inquiryModalPreviewHandler}
        handleConfirm={handleInquirySubmit}
      />
    </section>
  );
};

export default AskQuestion;
