import React from "react";
import { Col, Form, Modal, Row } from "antd";
import { CommonButton, CommonTextAreaField } from "../../../common";
import { Images } from "../../../../theme";
import "./styles.scss";
import { inputFieldRule } from "../../../../utils";

const ConfirmBidModal = ({ handleClose, preview, handleConfirm }) => {
  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={633}
      className="confirm-bid-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <div className="title">
            <h3>Mark as Completed</h3>
            <p>Are you sure job completed $150.00?</p>
          </div>
          <div className="confirm-amount">$150.00</div>
          <div className="modal-footer">
            <CommonButton
              text={"Completed"}
              borderRadius={"12px"}
              width={"200px"}
              height={"56px"}
              onClick={handleConfirm}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmBidModal;
