import { Table } from "antd";
import React from "react";
import "./styles.scss";

const CommonTable = ({ dataSource, columns, loading, width = 750 }) => {
  return (
    <Table
      pagination={false}
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      scroll={{
        x: width,
        // y: 500,
      }}
    />
  );
};

export default CommonTable;
