import React from "react";
import { useState } from "react";
import { BidsListing, BidsTable, CardsSection } from "../../../components";
import {
  CommonHeading,
  CommonTabs,
  CommonTextField,
} from "../../../components/common";
import { BID_FILTERS_LIST } from "../../../constants";
import { Images } from "../../../theme";
import "./styles.scss";
const MyBids = () => {
  //STATES
  const [selectedFilter, setSelectedFilter] = useState("ongoing");
  //HANDLERS
  const setFilterHandler = (data) => {
    setSelectedFilter(data);
  };
  return (
    <section className="bids-listing-wrapper">
      <CommonTextField
        text={"My Bids"}
        fontFamily="seventyTwoBold"
        fontSize={"34px"}
        color="#021349"
        mb={"12px"}
        lineHeight="42px"
      />{" "}
      <div className="bids-section">
        <div className="heading-wrapper">
          <CommonTextField
            text={"Bids"}
            fontFamily="seventyTwoBold"
            fontSize={"24px"}
          />
          <div className="table-filters-wrapper">
            <div className="search-box">
              <Images.Search />
              <input
                type="text"
                placeholder={"Search any Bids..."}
                // onChange={(e) => {
                //   handleSearch(e.target.value);
                // }}
              />
            </div>
            <CommonTabs
              buttonsList={BID_FILTERS_LIST}
              setState={setFilterHandler}
              state={selectedFilter}
            />
          </div>
        </div>
        <BidsListing filter={selectedFilter} />
      </div>
    </section>
  );
};

export default MyBids;
