import React, { useState } from "react";
import "./styles.scss";
import {
  CommonButton,
  CommonHeading,
  CommonModal,
  CommonTextField,
} from "../../common";
import { Divider, Space } from "antd";
import { AppStyles, Images } from "../../../theme";
import {
  ALERT_TYPES,
  BUYER_SIDEBAR_ITEMS,
  CREATE_BID_ROUTE,
  DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  SIDEBAR_ITEMS,
} from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest, userSignOutRequest } from "../../../redux/slicers/user";
import { toastAlert } from "../../../utils";

const Sidebar = ({ className, closeDrawer = () => {} }) => {
  const [logout, setLogout] = useState(false);
  const location = useLocation();
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const { isSeller } = useSelector((state) => state?.user);
  const sideBarItems = isSeller ? BUYER_SIDEBAR_ITEMS : SIDEBAR_ITEMS;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className={className ? className : "sidebar-parent"}>
      <div className="dot-parent">
        <div className="dot"></div>
      </div>
      <div className="test"></div>
      <div className="main-logo">
        <Images.Mainlogo
          className="c-p"
          onClick={() => navigate(DASHBOARD_ROUTE)}
        />
      </div>
      {!isSeller && (
        <div className="create-bid" onClick={() => navigate(CREATE_BID_ROUTE)}>
          <CommonTextField text={"Create Bid"} fontWeight={600} />
          <img src={Images.addIcon} width={"40px"} height={"40px"} />
        </div>
      )}
      <Divider />
      <div className="content-parent">
        {sideBarItems.map((t) => {
          const isActive = t?.active?.some((route) => {
            const regex = new RegExp(`^${route.replace(/:\w+/g, "\\w+")}$`);
            return regex.test(location.pathname);
          });

          return (
            <div
              className={`${isActive && "active"} item `}
              key={Math.random()}
            >
              <div
                className="content"
                onClick={() => {
                  navigate(t.route);
                  closeDrawer();
                }}
              >
                {t.src}
                <CommonTextField text={t.text} />
              </div>
              {isActive && (
                <div className="active-dot">
                  <div className="dot"></div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Divider />

      <div className="logout-but">
        <CommonButton text={"Logout"} onClick={() => setLogout(true)} />
      </div>
      <CommonModal
        isModalVisible={logout}
        setIsModalVisible={setLogout}
        discription="Do you want to logout?"
        onConfirm={() => {
          dispatch(logoutRequest());
          toastAlert("Logout Successfully!", ALERT_TYPES.success);
          navigate(LOGIN_ROUTE);
        }}
      ></CommonModal>
    </div>
  );
};

export default Sidebar;
