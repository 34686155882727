import React from "react";
import "./styles.scss";
import { BidOpportunity, WhyCentral } from "../../../components";
const Home = () => {
  return (
    <section className="home-wrapper">
      <WhyCentral />
      <BidOpportunity />
    </section>
  );
};

export default Home;
