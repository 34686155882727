import React from "react";
import "./styles.scss";
import { CommonHeading, CommonTextField } from "../../../components/common";
import { Col, Row, Space } from "antd";
import { Images } from "../../../theme";
import { BidsTable, CardsSection } from "../../../components";
const Dashboard = () => {
  return (
    <section className="dashboard">
      <CommonTextField text={"Company Profile"} fontWeight={600} />
      <CommonHeading text={"Welcome Back Sarah!"} mb={"8px"} mt={"8px"} />
      <CardsSection />
      <div className="bids-section">
        <div className="heading-wrapper">
          <CommonTextField
            text={"Bids"}
            fontFamily="seventyTwoBold"
            fontSize={"24px"}
          />
          <div
            className="table-filters-wrapper"
            style={{ width: "469px", maxWidth: "100%" }}
          >
            <div className="search-box" style={{ width: "100%" }}>
              <Images.Search />
              <input
                type="text"
                placeholder={"Search any Bids..."}
                // onChange={(e) => {
                //   handleSearch(e.target.value);
                // }}
              />
            </div>
          </div>
        </div>
        <BidsTable />
      </div>
    </section>
  );
};

export default Dashboard;
