import React from "react";
import { CommonButton, CommonHeading, CommonTextField } from "../../../common";
import "./styles.scss";
import { WHY_CENTRAl_BID_CONTENT } from "../../../../constants";
import { Col, Row } from "antd";

const WhyCentral = () => {
  return (
    <Row gutter={[40, 40]} className="why-Central-parent">
      <Col md={24} lg={12} className="left-side">
        <CommonHeading text="Why Central Bid?" />
        <CommonTextField text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam" />
        <CommonTextField text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam" />
        <CommonButton text={"Get Started"} />
      </Col>
      <Col md={24} lg={12} className="right-side">
        {WHY_CENTRAl_BID_CONTENT.map((t) => (
          <div key={Math.random()}>
            <div className="icon">{t.icon}</div>
            <CommonTextField text={t.title} />
            <CommonTextField text={t.text} />
          </div>
        ))}
      </Col>
    </Row>
  );
};

export default WhyCentral;
