import React, { useCallback, useState } from "react";
import {
  CommonModal,
  CommonSearch,
  CommonTable,
  CommonTextField,
} from "../../../common";
import "./styles.scss";
import {
  BIDS_DETAIL,
  BRANCH_TABlE_DATA,
  EDIT_BID_ROUTE,
} from "../../../../constants";
import { replaceValInString, toastAlert } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import CommonThreeDotMenu from "../../../common/CommonThreeDotMenu";
import { Space } from "antd";
import { Images } from "../../../../theme";

const BidsTable = () => {
  const [deleteModalPreview, setDeleteModalPreview] = useState();
  const navigate = useNavigate();
  const renderServiceColumn = (text) => (
    <CommonTextField text={text} className={"light-text"} fontWeight={700} />
  );

  const renderActionColumn = (_, data) => (
    <Space>
      <div className={`statusButton`}>
        <CommonTextField
          text={"Open"}
          className={"text-11"}
          fontWeight={700}
          textTransform="capitalize"
        />
      </div>
      <CommonThreeDotMenu
        onEdit={() =>
          navigate(replaceValInString(EDIT_BID_ROUTE, { ":id": data.key }))
        }
        onDelete={() => setDeleteModalPreview(true)}
      />
    </Space>
  );

  const renderNameColumn = (text) => (
    <CommonTextField
      text={text}
      fontWeight={700}
      className="c-p"
      onClick={() => navigate(replaceValInString(BIDS_DETAIL, { ":id": 1 }))}
    />
  );

  const columns = [
    {
      title: "Bid Name",
      dataIndex: "bidName",
      key: "bidName",
      render: renderNameColumn,
      sorter: {
        compare: (a, b) => a.bidName.localeCompare(b.bidName),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Published Date",
      dataIndex: "publishedDate",
      key: "publishedDate",
      render: renderServiceColumn,
      sorter: {
        compare: (a, b) =>
          new Date(a.publishedDate) - new Date(b.publishedDate),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Closing Date",
      dataIndex: "closingDate",
      key: "closingDate",
      render: renderServiceColumn,
      sorter: {
        compare: (a, b) => new Date(a.closingDate) - new Date(b.closingDate),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: renderServiceColumn,
      sorter: {
        compare: (a, b) => a.organization.localeCompare(b.organization),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Status",
      key: "action",
      width: 150,
      align: "center",
      render: renderActionColumn,
    },
  ];

  return (
    <div>
      {/* <CommonSearch /> */}
      <CommonTable columns={columns} dataSource={BRANCH_TABlE_DATA} />
      <CommonModal
        isModalVisible={deleteModalPreview}
        setIsModalVisible={setDeleteModalPreview}
        discription="Do you want to delete this bid?"
        onConfirm={() => {
          setDeleteModalPreview(false);
          toastAlert("Bid deleted successfully.");
        }}
      ></CommonModal>
    </div>
  );
};

export default BidsTable;
