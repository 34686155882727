import { Col, DatePicker, Form, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DASHBOARD_ROUTE, DATE_RULE } from "../../../constants";
import { inputFieldRule, toastAlert } from "../../../utils";
import {
  CommonButton,
  CommonDatePicker,
  CommonInputField,
  CommonModal,
  CommonSwitch,
  CommonTextAreaField,
  CommonTextField,
  CommonTimePicker,
  DocUploader,
} from "../../common";

function CreateBidForm() {
  //STATES
  const [inviteSupplier, setInviteSupplier] = useState(false);
  const [siteMeetings, setSiteMeetings] = useState(false);
  const [discard, setDiscard] = useState(false);
  //CONST VALS
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  //HANDLERS
  const handleCreateBid = () => {
    navigate(DASHBOARD_ROUTE);
    toastAlert(`Bid ${id ? "edited" : "created"} successfully.`);
  };

  const handleDiscard = () => {
    setDiscard(true);
  };

  const setValuesHandler = () => {
    const vals = Object.keys(form.getFieldsValue());
    vals.forEach((x) => {
      if (x !== "closingDate" && x !== "date") {
        form.setFieldValue(x, "Lorem ipsum dolor sit amet, ");
      } else {
        form.setFieldValue(x, moment());
      }
    });
  };

  //HOOKS
  useEffect(() => {
    if (id) {
      setValuesHandler();
    }
  }, [id]);
  return (
    <>
      <Form form={form} onFinish={handleCreateBid}>
        <Row gutter={[22, 0]}>
          <Col span={24}>
            <CommonInputField
              placeholder={"Bid Classification"}
              name="bidClassification"
              rules={inputFieldRule({
                name: "Bid classification",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={24}>
            <CommonInputField
              placeholder={"Bid Type"}
              name="bidType"
              rules={inputFieldRule({
                name: "Bid type",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Bid Number"}
              name="bidNumber"
              rules={inputFieldRule({
                name: "Bid number",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Bid Name"}
              name="bidName"
              rules={inputFieldRule({
                name: "Bid name",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={24}>
            <CommonInputField
              placeholder={"Bid Status"}
              name="bidStatus"
              rules={inputFieldRule({
                name: "Bid status",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            {/* <CommonInputField
              placeholder={"Bid Closing Date"}
              name="closingDate"
              rules={inputFieldRule({
                name: "Bid closing date",
                isMax: true,
                max: 100,
              })}
            /> */}
            <CommonDatePicker
              placeholder={"Bid Closing Date"}
              name="closingDate"
              rules={[
                {
                  validator: (_, value) => {
                    return DATE_RULE(_, value);
                  },
                },
              ]}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Question Deadline"}
              name="questionDeadline"
              rules={inputFieldRule({
                name: "Question deadline",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={24}>
            <CommonInputField
              placeholder={"Electronic Auctions"}
              name="eAuctions"
              rules={inputFieldRule({
                name: "Electronic auctions",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Submission Type"}
              name="submissionType"
              rules={inputFieldRule({
                name: "Submission type",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Submission Address"}
              name="submissionAddress"
              rules={inputFieldRule({
                name: "Submission address",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Public Opening"}
              name="publicOpening"
              rules={inputFieldRule({
                name: "Public opening",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Categories"}
              name="categories"
              rules={inputFieldRule({
                name: "Categories",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "22px",
            }}
          >
            <CommonTextField
              text={"Invite suppliers if job is close bid"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
            />
            <CommonSwitch state={inviteSupplier} setState={setInviteSupplier} />
          </Col>
          <Col span={24}>
            <CommonInputField
              placeholder={"Email Address"}
              name="email"
              rules={inputFieldRule({
                isEmail: true,
                name: "Email address",
                isMax: true,
                max: 100,
                isRequired: inviteSupplier,
              })}
              disabled={!inviteSupplier}
            />
          </Col>
          <Col span={24}>
            <CommonTextAreaField
              placeholder={"Description"}
              name="description"
              rules={inputFieldRule({
                name: "Description",
                isRequired: inviteSupplier,
              })}
              disabled={!inviteSupplier}
              height={"165px"}
            />
          </Col>
          <Col span={24}>
            <CommonTextAreaField
              placeholder={"Bid document access"}
              name="docAccess"
              rules={inputFieldRule({
                name: "Bid Document Access",
                isRequired: inviteSupplier,
              })}
              disabled={!inviteSupplier}
              height={"165px"}
            />
          </Col>
          <Col
            span={24}
            style={{
              marginBottom: "22px",
            }}
          >
            <CommonTextField
              text={"Company Tracker"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Company Name"}
              name="companyName"
              rules={inputFieldRule({
                name: "Company name",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Contact"}
              name="companyContact"
              rules={inputFieldRule({
                name: "Contact",
                isMax: true,
                max: 100,
              })}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "22px",
            }}
          >
            <CommonTextField
              text={"Site Meetings"}
              fontFamily="seventyTwoSemiBold"
              fontSize={"16px"}
            />
            <CommonSwitch state={siteMeetings} setState={setSiteMeetings} />
          </Col>
          <Col span={24}>
            <CommonInputField
              placeholder={"Meeting Location"}
              name="meetingLocation"
              rules={inputFieldRule({
                name: "Meeting location",
                isMax: true,
                max: 100,
                isRequired: siteMeetings,
              })}
              disabled={!siteMeetings}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            {/* <CommonInputField
              placeholder={"Date"}
              name="date"
              rules={inputFieldRule({
                name: "Date",
                isMax: true,
                max: 100,
                isRequired: siteMeetings,
              })}
              disabled={!siteMeetings}
            /> */}
            <CommonDatePicker
              placeholder={"Date"}
              name="date"
              rules={[
                {
                  validator: (_, value) => {
                    return DATE_RULE(_, value);
                  },
                },
              ]}
              disabled={!siteMeetings}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            {/* <CommonInputField
              placeholder={"Time"}
              name="time"
              rules={inputFieldRule({
                name: "Time",
                isMax: true,
                max: 100,
                isRequired: siteMeetings,
              })}
              disabled={!siteMeetings}
            /> */}
            <CommonTimePicker
              placeholder={"Time"}
              name="time"
              rules={[
                {
                  validator: (_, value) => {
                    return DATE_RULE(_, value);
                  },
                },
              ]}
              disabled={!siteMeetings}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Mandatory"}
              name="mandatory"
              rules={inputFieldRule({
                name: "Mandatory",
                isMax: true,
                max: 100,
                isRequired: siteMeetings,
              })}
              disabled={!siteMeetings}
            />
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <CommonInputField
              placeholder={"Meeting Documents"}
              name="meetingDocuments"
              rules={inputFieldRule({
                name: "Meeting documents",
                isMax: true,
                max: 100,
                isRequired: siteMeetings,
              })}
              disabled={!siteMeetings}
            />
          </Col>
          <Col span={24}>
            <CommonTextAreaField
              placeholder={"Description"}
              name="meetingDescription"
              rules={inputFieldRule({
                name: "Description",
                isRequired: siteMeetings,
              })}
              disabled={!siteMeetings}
              height={"165px"}
            />
          </Col>
          <Col span={24} style={{ marginBottom: "30px" }}>
            <Form.Item name={"docUploader"}>
              <DocUploader
                docType="Doc"
                maxSize={10}
                resetField={() => form.resetFields(["docUploader"])}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <DocUploader docType="Addenda" maxSize={10} />
          </Col>
        </Row>
        <div className="create-btns-wrapper">
          <CommonButton
            text={"Discard"}
            background="#031946"
            fontFamily="seventyTwoBold"
            fontSize="18px"
            onClick={handleDiscard}
          />

          <CommonButton
            text={`${id ? "Edit" : "Create"} Job`}
            fontFamily="seventyTwoBold"
            fontSize="18px"
            htmlType="submit"
          />
        </div>
      </Form>
      <CommonModal
        isModalVisible={discard}
        setIsModalVisible={setDiscard}
        discription="Do you want to discard your changes?"
        onConfirm={() => {
          setDiscard(false);
          navigate(DASHBOARD_ROUTE);
        }}
      ></CommonModal>
    </>
  );
}

export default CreateBidForm;
