import React, { useRef } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Col, message, Row, Upload } from "antd";
import { Images } from "../../../theme";
import CommonTextField from "../TextField";
import "./styles.scss";
import { useState } from "react";

function DocUploader({
  docType = "Doc",
  maxSize = 10,
  isCustom = false,
  customCode,
  resetField,
  multiple = true,
}) {
  //STATES
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState("");
  //CONST VALS
  const { Dragger } = Upload;
  const fileInputRef = useRef(null);
  const props = {
    name: "file",
    multiple: multiple,
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setFileList(info.fileList);
        if (!multiple) {
          setFileName(info.file.name);
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    beforeUpload: (file) => {
      const isLt10M = file.size / 1024 / 1024 < maxSize;
      if (!isLt10M) {
        message.error("Maximum upload size is 10 MB");
      }
      return isLt10M || Upload.LIST_IGNORE;
    },
  };

  //HANDLERS
  const removeFileHandler = (id) => {
    // resetField();
    let temp = [...fileList];
    let newTemp = temp.filter((x) => x.uid !== id);
    setFileList(newTemp);
    fileInputRef.current = null;
  };

  console.log(fileInputRef);

  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <Dragger
          {...props}
          className="custom-uploader"
          accept=".pdf"
          showUploadList={false}
          ref={fileInputRef}
        >
          {!isCustom ? (
            <>
              <p className="ant-upload-drag-icon">
                <Images.DocIcon />
              </p>
              <CommonTextField
                text={`Upload ${docType}`}
                color="#0070F2"
                fontFamily={"seventyTwoRegular"}
                fontSize="18px"
                mb={"5px"}
              />
              <CommonTextField
                text={`PDF files only. Max ${maxSize} MB each`}
                color="#021349"
                fontFamily={"seventyTwoRegular"}
                fontSize="14px"
              />
            </>
          ) : (
            <>
              {customCode}
              {fileName && <CommonTextField text={fileName} mt="10px" />}
            </>
          )}
        </Dragger>
      </Col>
      {multiple &&
        fileList?.map((item, index) => (
          <Col span={12} key={index}>
            <div className="file-item">
              <div className="item-info">
                <Images.DocumentIcon />
                <CommonTextField
                  text={item?.name}
                  fontFamily="seventyTwoLight"
                  color={"#0070F2"}
                />
              </div>
              <Images.Delete
                className="c-p"
                onClick={() => removeFileHandler(item.uid)}
              />
            </div>
          </Col>
        ))}
    </Row>
  );
}

export default DocUploader;
