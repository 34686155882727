import { Col, Row, Space } from "antd";
import React from "react";
import { CommonTextField } from "../../../common";
import { Images } from "../../../../theme";
import { useSelector } from "react-redux";

const CardsSection = () => {
  const { isSeller } = useSelector((state) => state.user);
  return (
    <div>
      <Row gutter={[20, 20]}>
        {!isSeller && (
          <Col xl={8} lg={10} md={24} sm={24} xs={24}>
            <div className="ongoing-bid">
              <Space size={18} direction="vertical">
                <CommonTextField
                  text={"Ongoing Bids"}
                  className={"light-text"}
                />
                <CommonTextField
                  text={"35"}
                  fontSize={"30px"}
                  fontWeight={700}
                />
              </Space>
              <img src={Images.onGoingIcon} />
            </div>
          </Col>
        )}
        <Col
          xl={isSeller ? 12 : 8}
          lg={isSeller ? 12 : 10}
          md={24}
          sm={24}
          xs={24}
        >
          <div className="ongoing-bid">
            <Space size={18} direction="vertical">
              <CommonTextField text={"Active Bids"} className={"light-text"} />
              <CommonTextField
                text={"100"}
                fontSize={"30px"}
                fontWeight={700}
              />
            </Space>
            <div className="icon-wrapper">
              <img src={Images.activeIcon} />
            </div>
          </div>
        </Col>
        <Col
          xl={isSeller ? 12 : 8}
          lg={isSeller ? 12 : 10}
          md={24}
          sm={24}
          xs={24}
        >
          <div className="ongoing-bid Completed">
            <Space size={18} direction="vertical">
              <CommonTextField
                text={"Completed Bids"}
                className={"white-text"}
              />
              <CommonTextField
                text={"65"}
                fontSize={"30px"}
                fontWeight={700}
                className={"white-text"}
              />
            </Space>
            <img src={Images.completeIcon} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CardsSection;
