import React, { useEffect, useState } from "react";
import "./styles.scss";
import { CommonHeading, CommonTextField } from "../../common";
import { Images } from "../../../theme";
import { Avatar, Drawer, Grid, Space } from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Sidebar";
import { DASHBOARD_ROUTE } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const { useBreakpoint } = Grid;

const Header = ({ title }) => {
  const [open, setOpen] = useState(false);
  const { isSeller } = useSelector((state) => state?.user);

  const showDrawer = () => {
    setOpen(true);
  };
  const location = useLocation();

  const onClose = () => {
    setOpen(false);
  };

  const screens = useBreakpoint();

  useEffect(() => {
    onClose();
  }, [location]);

  return (
    <header className="main-header">
      {!screens?.md && (
        <img
          className="sidebar-icon c-p"
          width={"20px"}
          height={"20px"}
          src={Images.hamburger}
          onClick={() => {
            showDrawer();
          }}
        />
      )}

      <Space size={20}>
        <div className="notification-parent">
          <div className="notification-circle"></div>
          <Images.NotificationIcon />
        </div>
        {isSeller ? (
          <>
            <Space size={13}>
              <CommonTextField
                text={"Leslie Alexander"}
                fontFamily={"seventyTwoBold"}
                fontSize={"12px"}
              />
              <Images.ProfileImage />
            </Space>
          </>
        ) : (
          <img src={Images.upLogo} />
        )}
      </Space>

      <Drawer
        title={
          <CommonTextField
            fontWeight={700}
            className={"logo theme-text"}
            text={"Central Bid"}
          />
        }
        placement="left"
        onClose={onClose}
        open={open}
        className="mobile-sidebar"
      >
        <Sidebar className="Mobile-sidebar-parent" closeDrawer={onClose} />
      </Drawer>
    </header>
  );
};

export default Header;
