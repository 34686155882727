import React, { useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonTextField,
} from "../../../../../../components/common";
import { Col, Row } from "antd";
import { Images } from "../../../../../../theme";

function Step6({ isHidden, handleNextStep }) {
  return (
    <div
      className={` ${isHidden ? "hidden" : ""} `}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Row gutter={[23, 23]}>
        <Col span={24}>
          <CommonTextField
            text={"Download PDF"}
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"20px"}
            lineHeight={"16.1px"}
            fontFamily={"seventyTwoSemiBold"}
            mt={"30px"}
          />
        </Col>
        <Col span={24}>
          <div className="bid-download">
            <CommonTextField text={"Bid.pdf"} fontFamily={"seventyTwoBold"} />
            <Images.DownloadIcon />
          </div>
        </Col>
      </Row>

      <div className="buttonWrapper" style={{ marginTop: "16em" }}>
        <CommonButton
          text={"Submit"}
          classname={"button"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={() => handleNextStep(7)}
        />
      </div>
    </div>
  );
}

export default Step6;
