import { createSlice } from "@reduxjs/toolkit";

const UserReducer = createSlice({
  name: "user",
  initialState: {
    data: {},
    isAuthenticated: false,
    isSeller: null,
  },
  reducers: {
    // USER LOGIN
    loginRequest() {},
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      console.log(action);
      state.isSeller = action.payload.payload.isSeller;
    },

    // USER SIGNOUT
    logoutRequest(state, action) {
      state.isAuthenticated = false;
      state.isSeller = false;
    },

    setSginupRoleRequest(state, action) {
      state.isSeller = action.payload.payload.isSeller;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  logoutRequest,
  setSginupRoleRequest,
} = UserReducer.actions;

export default UserReducer.reducer;
