import React, { useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonInputField,
  CommonTable,
  CommonTextField,
} from "../../../../../../components/common";
import { Form, Col, Row, Space } from "antd";
import { TABLE_DATA, TABLE_DATA_STEP_1 } from "../../../../../../constants";
import { creditCardValidator, inputFieldRule } from "../../../../../../utils";

function Step1({ isHidden, handleNextStep }) {
  //CONST VALS
  const [step1Form] = Form.useForm();
  const renderTableText = (text) => (
    <CommonTextField
      text={text}
      fontFamily={"seventyTwoRegular"}
      fontSize={"12px"}
      lineHeight={"13.8px"}
      color={"rgba(2, 19, 73, 1)"}
    />
  );
  const renderFormItem = (text) => (
    <CommonInputField
      placeholder={"Type"}
      name={text}
      rules={inputFieldRule({ isRequired: false })}
      onKeyDown={(e) => creditCardValidator(e, "card", 3)}
    />
  );

  const renderTableTotal = (text) => (
    <Space>
      <CommonTextField
        text={text}
        fontFamily={"seventyTwoRegular"}
        fontSize={"12px"}
        lineHeight={"13.8px"}
        color={"rgba(2, 19, 73, 1)"}
      />
      <CommonTextField
        text={"Edit"}
        fontFamily={"seventyTwoRegular"}
        fontSize={"12px"}
        lineHeight={"13.8px"}
        color={"rgba(2, 19, 73, 1)"}
      />
    </Space>
  );

  const columns = [
    {
      title: "Table",
      dataIndex: "table",
      key: "table",
      render: renderTableText,
    },
    {
      title: "# of Rows",
      dataIndex: "noOfRows",
      key: "noOfRows",
      align: "center",
      render: renderTableText,
    },
    {
      title: "# of Required Rows",
      dataIndex: "noOfRequiredRows",
      key: "noOfRequiredRows",
      align: "center",
      render: renderTableText,
    },
    {
      title: "# of Optional Rows",
      dataIndex: "noOfOptionalRows",
      key: "noOfOptionalRows",
      align: "center",
      render: renderTableText,
    },
    {
      title: "Mandatory",
      dataIndex: "mandatory",
      key: "mandatory",
      align: "center",
      render: renderTableText,
    },
    {
      title: "Sub Total",
      dataIndex: "subTotal",
      key: "subTotal",
      align: "center",
      render: renderTableTotal,
    },
  ];
  const pricingInformationColumn = [
    {
      title: "Descriptions",
      dataIndex: "description",
      key: "table",
      render: renderTableText,
      width: "400px",
    },
    {
      title: "Hourly rate 2024",
      dataIndex: "hr2024",
      key: "noOfRows",
      align: "center",
      render: renderFormItem,
      width: "200px",
    },
    {
      title: "Hourly rate 2025",
      dataIndex: "hr2025",
      key: "noOfRequiredRows",
      align: "center",
      render: renderFormItem,
      width: "200px",
    },
    {
      title: "Hourly rate 2026",
      dataIndex: "hr2026",
      key: "noOfOptionalRows",
      align: "center",
      render: renderFormItem,
      width: "200px",
    },
  ];

  return (
    <div className={`${isHidden ? "hidden" : ""} `}>
      <Row>
        <Col lg={14}>
          <CommonTextField
            text={"Schedule of Prices"}
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"20px"}
            lineHeight={"23px"}
            fontFamily={"seventyTwoSemiBold"}
          />
        </Col>

        <Col lg={22} style={{ marginTop: 15 }}>
          <CommonTextField
            text={
              "The Bidder hereby Bids and offers to enter into the Contract referred to and to supply and do all or any part of the Work which is set out or called for in this Bid, at the unit prices, and/or lump sums, hereinafter stated. HST is additional."
            }
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontFamily={"seventyTwoRegular"}
          />
          <CommonTextField
            text={"* Denotes a 'MANDATORY' field"}
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontFamily={"seventyTwoRegular"}
            mt={"20px"}
          />
          <CommonTextField
            text={
              "Do not enter $0.00 dollars unless you are providing the line item at zero dollars to the Owner (unless otherwise specified). If the line item and/or table is 'NON-MANDATORY' and you are not bidding on it, leave the table and/or line item blank.Do not enter a $0.00 dollar value."
            }
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontFamily={"seventyTwoRegular"}
            mt={"20px"}
          />
        </Col>
      </Row>

      <div className="tableWrapper">
        <CommonTable columns={columns} dataSource={TABLE_DATA} />
      </div>

      <CommonTextField
        text={"Pricing Information - Landscape Architects"}
        color={"rgba(2, 19, 73, 1)"}
        fontSize={"20px"}
        lineHeight={"23px"}
        fontFamily={"seventyTwoSemiBold"}
        mt={"20px"}
      />

      <div className="tableWrapper pricing-table-wrapper">
        <Form form={step1Form}>
          <CommonTable
            columns={pricingInformationColumn}
            dataSource={TABLE_DATA_STEP_1}
          />
        </Form>
      </div>

      <div className="buttonWrapper">
        <CommonButton
          text={"Continue"}
          classname={"button"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={() => handleNextStep(2)}
        />
      </div>
    </div>
  );
}

export default Step1;
