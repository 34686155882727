import { Carousel, Form } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthCarousel } from "../../components";
import {
  CommonButton,
  CommonHeading,
  CommonInputField,
  CommonPasswordInput,
  CommonTextField,
} from "../../components/common";
import {
  EMAIL_VERIFICATION_ROUTE,
  RESET_PASSWORD_ROUTE,
} from "../../constants";
import { CustomDispatch } from "../../helpers";
import { loginSuccess } from "../../redux/slicers/user";
import { Images } from "../../theme";
import { inputFieldRule } from "../../utils";
import "./auth.scss";

const ForgotPassword = () => {
  //CONST VALS
  const navigate = useNavigate();
  const [form] = Form.useForm();

  //CUSTOM DISPATCH

  //HANDLERS
  const handleLogin = (vals) => {
    console.log(vals);
    navigate(EMAIL_VERIFICATION_ROUTE);
  };
  return (
    <div className="auth-box">
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-form-wrapper">
            <CommonHeading
              text={"Forget Password"}
              fontSize="39px"
              fontWeight={"600"}
              color="#031946"
              mb={"12px"}
              // whiteSpace="nowrap"
            />
            <CommonTextField
              text={
                "You’ll get an Phone number. Open the Message and find the verification code"
              }
              fontSize="14px"
              fontWeight={"400"}
              color="#465E7D"
              mb={"30px"}
            />
            <Form form={form} className="login-form" onFinish={handleLogin}>
              <CommonInputField
                placeholder={"Enter your email"}
                prefix={<Images.EmailIcon />}
                rules={inputFieldRule({
                  name: "Email",
                  isEmail: true,
                  isMax: true,
                  max: 100,
                })}
                name="Email"
              />
              <CommonButton
                text={"Send"}
                fontSize="18px"
                fontWeight={"400"}
                height="56px"
                htmlType="submit"
              />
            </Form>
          </div>
        </div>
      </div>
      <div className="auth-image-wrapper">
        <img src={Images.ForgotPassImage} alt="Login Banner" />

        <AuthCarousel />
      </div>
    </div>
  );
};
export default ForgotPassword;
