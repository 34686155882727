import { Col, Row } from "antd";
import React from "react";
import {
  CommonButton,
  CommonTextField,
} from "../../../../../components/common";
import { useParams } from "react-router-dom";
import { Images } from "../../../../../theme";

function CommonBidData({ id, isSeller }) {
  return (
    <div>
      <Row gutter={[24, 20]} style={{ marginTop: isSeller ? 30 : 0 }}>
        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Bid Name"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={
              "60642262 - Peter Ballantyne Cree Nation, Pelican Narrows Sewage Pump Station No. 4 and No. 5 Upgrades, ISC Project No. CT808"
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={3} xs={24}>
          <CommonTextField
            text={"Bid Classification"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Services"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={6} xs={24}>
          <CommonTextField
            text={"Bid Type"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"RFP"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={7} xs={24}>
          <CommonTextField
            text={"Bid Number"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"781900-RFP-2023-EWSI-90-SK-Regina-JH"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={8} xs={24}>
          <CommonTextField
            text={"Bid Name"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Regina WWTP Expansion - Conceptual Design"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={3} xs={24}>
          <CommonTextField
            text={"Bid Status"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Open"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={6} xs={24}>
          <CommonTextField
            text={"Bid Closing Date"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Fri Feb 16, 2024 2:00:59 PM (MST)"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={7} xs={24}>
          <CommonTextField
            text={"Question Deadline"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Fri Feb 9, 2024 2:00:00 PM (MST)"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={8} xs={24}>
          <CommonTextField
            text={"Duration in months"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"1"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Negotiation Type"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Refer to project document"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Condition for Participation"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Refer to project document"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={12} xs={24}>
          <CommonTextField
            text={"Electronic Auctions"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Not Applicable"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Submission Type"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Online Submissions Only"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Submission Address"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Online Submissions Only"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Public Opening"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"No"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Description"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={
              "The intent of this RFP is to select a engineering firm to provide design and engineering services for the conceptual design of the Regina WWTP expansion."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Bid Document Access"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={
              "Bid Opportunity notices and awards and a free preview of the bid documents is available on this site free of charge without registration. Please note, some documents may be secured and you will be required to register for the bid to download and view the documents. There is no cost to obtain an unsecured version of the document and /or to participate in this solicitation."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Trade Agreements"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Show Trade Agreements [+]"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Categories"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Show Categories [+]"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col span={24}>
          <div className="separator"></div>
        </Col>
        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Meeting Locations"}
            fontFamily={"seventyTwoSemiBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            color={"rgba(2, 19, 73, 1)"}
          />
          <CommonTextField
            text={
              "The following are the meeting times and locations for the bid:"
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
        </Col>
        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Meeting Location"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Regina WWTP - 100 Fleming Road, Regina, SK S4M0A1"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Description"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={
              "Refer section 2.1 of the RFP Main Body for additional details regarding the site visit. Please RSVP James Howard - jhoward@epcor.com if you plan on attending the meeting."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col lg={8} xs={24}>
          <CommonTextField
            text={"Date/Time"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"Thursday January 11, 2024 01:00 PM (MST)"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>

        <Col lg={8} xs={24}>
          <CommonTextField
            text={"Mandatory"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
          />
          <CommonTextField
            text={"No"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"4px"}
          />
        </Col>
        <Col span={24}>
          <CommonTextField
            text={"Meeting Documents"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mt={"20px"}
          />
        </Col>
        <Col lg={12} xs={24}>
          <div className="downloadFileWrapper">
            <div className="downloadFileWrap">
              <Images.DocumentIcon />
              <CommonTextField
                text={"Thursday January 11, 2024 01:00 PM (MST)"}
                fontFamily={"seventyTwoRegular"}
                fontSize={"14px"}
                lineHeight={"16.1px"}
                color={"rgba(0, 112, 242, 1)"}
              />
            </div>
            <Images.DownloadIcon />
          </div>
        </Col>

        <Col lg={12} xs={24}>
          <div className="downloadFileWrapper">
            <div className="downloadFileWrap">
              <Images.DocumentIcon />
              <CommonTextField
                text={"Thursday January 11, 2024 01:00 PM (MST)"}
                fontFamily={"seventyTwoRegular"}
                fontSize={"14px"}
                lineHeight={"16.1px"}
                color={"rgba(0, 112, 242, 1)"}
              />
            </div>
            <Images.DownloadIcon />
          </div>
        </Col>

        <Col lg={12} xs={24}>
          <div className="downloadFileWrapper">
            <div className="downloadFileWrap">
              <Images.DocumentIcon />
              <CommonTextField
                text={"Thursday January 11, 2024 01:00 PM (MST)"}
                fontFamily={"seventyTwoRegular"}
                fontSize={"14px"}
                lineHeight={"16.1px"}
                color={"rgba(0, 112, 242, 1)"}
              />
            </div>
            <Images.DownloadIcon />
          </div>
        </Col>

        <Col lg={12} xs={24}>
          <div className="downloadFileWrapper">
            <div className="downloadFileWrap">
              <Images.DocumentIcon />
              <CommonTextField
                text={"Thursday January 11, 2024 01:00 PM (MST)"}
                fontFamily={"seventyTwoRegular"}
                fontSize={"14px"}
                lineHeight={"16.1px"}
                color={"rgba(0, 112, 242, 1)"}
              />
            </div>
            <Images.DownloadIcon />
          </div>
        </Col>
        <Col span={24}>
          <div className="separator"></div>
        </Col>
        <Col span={24}>
          <div className="documentMainWrapper">
            <CommonTextField
              text={"Documents"}
              fontFamily={"seventyTwoSemiBold"}
              fontSize={"24px"}
              lineHeight={"36px"}
              color={"rgba(2, 19, 73, 1)"}
            />
            <div className="documentWrapper">
              <div className="documentItemWrapper documentItemWrapper1">
                <CommonTextField
                  text={"RFP Documents"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                />
                <CommonTextField
                  text={"Thursday December 21, 2023 05:17 PM"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                />
              </div>
              <div className="documentItemWrapper documentItemWrapper2">
                <div>
                  <CommonTextField
                    text={"Pages"}
                    fontFamily={"seventyTwoRegular"}
                    fontSize={"12px"}
                    lineHeight={"13.8px"}
                    color={"rgba(70, 94, 125, 0.5)"}
                    textAlign={"right"}
                  />
                  <CommonTextField
                    text={"159"}
                    fontFamily={"seventyTwoRegular"}
                    fontSize={"14px"}
                    lineHeight={"16.1px"}
                    color={"rgba(2, 19, 73, 1)"}
                    mt={"4px"}
                    textAlign={"right"}
                  />
                </div>
                {(isSeller ? id === "ongoing" : true) && (
                  <CommonButton
                    text={"Download"}
                    background={"rgba(3, 25, 70, 1)"}
                    fontFamily={"seventyTwoBold"}
                    fontSize={"18px"}
                    lineHeight={"20.7px"}
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className="separator"></div>
        </Col>
        <Col span={24}>
          <div className="documentMainWrapper">
            <CommonTextField
              text={"Addenda"}
              fontFamily={"seventyTwoSemiBold"}
              fontSize={"24px"}
              lineHeight={"36px"}
              color={"rgba(2, 19, 73, 1)"}
            />
            <div className="documentWrapper">
              <div className="documentItemWrapper documentItemWrapper1">
                <CommonTextField
                  text={"RFP Documents"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                />
                <CommonTextField
                  text={"Thursday December 21, 2023 05:17 PM"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                />
              </div>
              <div className="documentItemWrapper documentItemWrapper2">
                <div>
                  <CommonTextField
                    text={"Pages"}
                    fontFamily={"seventyTwoRegular"}
                    fontSize={"12px"}
                    lineHeight={"13.8px"}
                    color={"rgba(70, 94, 125, 0.5)"}
                    textAlign={"right"}
                  />
                  <CommonTextField
                    text={"159"}
                    fontFamily={"seventyTwoRegular"}
                    fontSize={"14px"}
                    lineHeight={"16.1px"}
                    color={"rgba(2, 19, 73, 1)"}
                    mt={"4px"}
                    textAlign={"right"}
                  />
                </div>
                {(isSeller ? id === "ongoing" : true) && (
                  <CommonButton
                    text={"Download"}
                    background={"rgba(3, 25, 70, 1)"}
                    fontFamily={"seventyTwoBold"}
                    fontSize={"18px"}
                    lineHeight={"20.7px"}
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className="separator"></div>
        </Col>
        <Col span={24}>
          <div className="planMainWrapper">
            <CommonTextField
              text={"Plan Takers"}
              fontFamily={"seventyTwoSemiBold"}
              fontSize={"24px"}
              lineHeight={"36px"}
              color={"rgba(2, 19, 73, 1)"}
            />
            <CommonTextField
              text={"The following are the plan takers for the bid:"}
              fontFamily={"seventyTwoRegular"}
              fontSize={"12px"}
              lineHeight={"13.8px"}
              color={"rgba(70, 94, 125, 0.5)"}
              className={"planDesc"}
            />

            <div className="planWrapper">
              <div className="planItemWrapper planItemWrapper1">
                <CommonTextField
                  text={"Company"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                />
                <CommonTextField
                  text={"AECOM Canada Ltd."}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                />
              </div>

              <div className="planItemWrapper planItemWrapper2">
                <CommonTextField
                  text={"Contact"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                  textAlign={"right"}
                />
                <CommonTextField
                  text={
                    "Vanthuyne, Jolene 200 - 2100 8th Street East, Saskatoon Saskatchewan, Canada S7H 0V1"
                  }
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                  textAlign={"right"}
                />
              </div>
            </div>

            <div className="separator"></div>

            <div className="planWrapper">
              <div className="planItemWrapper planItemWrapper1">
                <CommonTextField
                  text={"Company"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                />
                <CommonTextField
                  text={"AECOM Canada Ltd."}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                />
              </div>

              <div className="planItemWrapper planItemWrapper2">
                <CommonTextField
                  text={"Contact"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                  textAlign={"right"}
                />
                <CommonTextField
                  text={
                    "Vanthuyne, Jolene 200 - 2100 8th Street East, Saskatoon Saskatchewan, Canada S7H 0V1"
                  }
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                  textAlign={"right"}
                />
              </div>
            </div>

            <div className="separator"></div>

            <div className="planWrapper">
              <div className="planItemWrapper planItemWrapper1">
                <CommonTextField
                  text={"Company"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                />
                <CommonTextField
                  text={"AECOM Canada Ltd."}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                />
              </div>

              <div className="planItemWrapper planItemWrapper2">
                <CommonTextField
                  text={"Contact"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  lineHeight={"13.8px"}
                  color={"rgba(70, 94, 125, 0.5)"}
                  textAlign={"right"}
                />
                <CommonTextField
                  text={
                    "Vanthuyne, Jolene 200 - 2100 8th Street East, Saskatoon Saskatchewan, Canada S7H 0V1"
                  }
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                  color={"rgba(2, 19, 73, 1)"}
                  mt={"4px"}
                  textAlign={"right"}
                />
              </div>
            </div>
          </div>{" "}
        </Col>
        <Col span={24}>
          <div className="separator"></div>
        </Col>
      </Row>
    </div>
  );
}

export default CommonBidData;
