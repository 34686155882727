import React, { useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonInputField,
  CommonTable,
  CommonTextField,
} from "../../../../../../components/common";
import { Checkbox, Col, Form, Radio, Row } from "antd";
import { inputFieldRule, toastAlert } from "../../../../../../utils";
import {
  ALERT_TYPES,
  TABLE_DATA,
  TABLE_DATA_STEP_4,
} from "../../../../../../constants";

function Step4({ isHidden, handleNextStep }) {
  //STATES
  const [fileCheckList, setFileCheckList] = useState([]);
  const [termsAgreed, setTermsAgreed] = useState(false);
  //CONST VALS
  const [form] = Form.useForm();

  //HANDLERS
  const handleFileCheck = (key) => {
    if (fileCheckList.includes(key)) {
      // Remove key from fileCheckList
      setFileCheckList(fileCheckList.filter((item) => item !== key));
    } else {
      // Add key to fileCheckList
      setFileCheckList([...fileCheckList, key]);
    }
  };

  //CUSTOM COMPONENTS
  const renderTableText = (text) => (
    <CommonTextField
      text={text}
      fontFamily={"seventyTwoRegular"}
      fontSize={"12px"}
      lineHeight={"13.8px"}
      color={"rgba(2, 19, 73, 1)"}
    />
  );
  const renderTableCheckBox = (data) => (
    <Checkbox
      checked={fileCheckList.includes(data)}
      onChange={() => handleFileCheck(data)}
    ></Checkbox>
  );

  const columns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      render: renderTableText,
    },
    {
      title:
        "I have reviewed the below addendum and attachments (if applicable)",
      dataIndex: "key",
      key: "key",
      align: "center",
      render: renderTableCheckBox,
    },
    {
      title: "Pages",
      dataIndex: "pages",
      key: "pages",
      align: "center",
      render: renderTableText,
    },
  ];

  //HANDLERS
  const handleStepFourForm = () => {
    form.validateFields();
    if (termsAgreed) {
      if (fileCheckList.length === TABLE_DATA_STEP_4.length) {
        form.submit();
      } else {
        toastAlert(
          "Please review all the files and addendums.",
          ALERT_TYPES.ERROR
        );
      }
    } else {
      toastAlert(
        "Please agree to the terms and conditions.",
        ALERT_TYPES.ERROR
      );
    }
  };

  const handleFormFinish = (vals) => {
    console.log(vals);
    handleNextStep(5);
  };

  return (
    <div className={` ${isHidden ? "hidden" : ""} `}>
      <Form form={form} style={{ marginTop: 20 }} onFinish={handleFormFinish}>
        <Row gutter={[12, 0]}>
          <Col
            className="checkbox-parent"
            span={24}
            style={{ marginBottom: "15px" }}
          >
            <Checkbox
              checked={termsAgreed}
              onChange={() => setTermsAgreed(!termsAgreed)}
            />
            <CommonTextField
              text={
                "I/WE agree to be bound by the terms and conditions and have authority to bind the Corporation and submit this Bid on behalf of the Bidder."
              }
            />
          </Col>

          <Col lg={12}>
            <CommonInputField
              placeholder={"First Name"}
              rules={inputFieldRule({
                name: "First Name",
                isMax: true,
                max: 30,
              })}
              name="firstName"
            />
          </Col>

          <Col lg={12}>
            <CommonInputField
              placeholder={"Last Name"}
              rules={inputFieldRule({
                name: "Last Name",
                isMax: true,
                max: 30,
              })}
              name="lastName"
            />
          </Col>

          <Col lg={12}>
            <CommonInputField
              placeholder={"Title"}
              rules={inputFieldRule({
                name: "Title",
                isMax: true,
                max: 30,
              })}
              name="title"
            />
          </Col>

          <Col lg={12}>
            <CommonInputField
              placeholder={"Legal Company Name"}
              rules={inputFieldRule({
                name: "Legal Company Name",
                isMax: true,
                max: 30,
              })}
              name="legalCompanyName"
            />
          </Col>

          <Col span={24}>
            <CommonTextField
              text={
                "Proponents must fullydisclose, in writing to the County on or before the Closing Date and Time ofthis Bid, the circumstances of any potential conflict of interest or what couldbe perceived as a possible conflict of interest if the Proponent were to becomea contracting party pursuant to this Bid. The County shall review anysubmissions by Proponents under this provision and may reject any Submissions where, in the sole opinion of County, the Proponent could be in a conflict ofinterest or could be perceived to be in a possible conflict of interestposition if the Proponent were to become a contracting party pursuant to this Bid."
              }
              color={"rgba(2, 19, 73, 1)"}
              fontSize={"14px"}
              lineHeight={"20px"}
              fontFamily={"seventyTwoRegular"}
              mt={"15px"}
              mb={"15px"}
            />
            <Radio.Group name="radioGroup" defaultValue={1}>
              <Radio value={1}>Yes</Radio>
              <Radio value={2}>No</Radio>
            </Radio.Group>
          </Col>

          <div></div>

          <Col span={24}>
            <CommonTextField
              text={
                "The Bidder acknowledges and agrees that the addendum/addenda below form part of the Bid Document"
              }
              color={"rgba(2, 19, 73, 1)"}
              fontSize={"14px"}
              lineHeight={"20px"}
              fontFamily={"seventyTwoRegular"}
              mt={"15px"}
            />
            <CommonTextField
              text={
                "Please check the box in the column 'I have reviewed this addendum' below to acknowledge each of the addenda."
              }
              color={"rgba(2, 19, 73, 1)"}
              fontSize={"14px"}
              lineHeight={"20px"}
              fontFamily={"seventyTwoRegular"}
            />
          </Col>

          <Col span={24} style={{ marginTop: 30 }} className="tableWrapper">
            <CommonTable columns={columns} dataSource={TABLE_DATA_STEP_4} />
          </Col>
        </Row>
      </Form>

      <div className="buttonWrapper">
        <CommonButton
          text={"Continue"}
          classname={"button"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={handleStepFourForm}
        />
      </div>
    </div>
  );
}

export default Step4;
