import ErrorThumbnail from "../assets/images/error-thumb.svg";
import { ReactComponent as BidsIcon } from "../assets/sidebarIcons/bids-icon.svg";
import { ReactComponent as MyBidsIcon } from "../assets/sidebarIcons/my-bids-icon.svg";
import { ReactComponent as DashboardIcon } from "../assets/sidebarIcons/dashboard-icon.svg";
import { ReactComponent as ProfileIcon } from "../assets/sidebarIcons/profile-icon.svg";
import { ReactComponent as SubscriptionIcon } from "../assets/sidebarIcons/subscription-icon.svg";
import upLogo from "../assets/headerIcons/up-logo.png";
import { ReactComponent as NotificationIcon } from "../assets/headerIcons/notification.svg";
import { ReactComponent as Mainlogo } from "../assets/sidebarIcons/main-logo.svg";
import addIcon from "../assets/sidebarIcons/add-icon.svg";
import hamburger from "../assets/headerIcons/hamburger.png";
import onGoingIcon from "../assets/dashboard/ongoing-icon.png";
import activeIcon from "../assets/dashboard/active-icon.png";
import completeIcon from "../assets/dashboard/complete.png";
import { ReactComponent as BetterBidIcon } from "../assets/home/better-icon.svg";
import { ReactComponent as WhyNotificationIcon } from "../assets/home/notification-icon.svg";
import { ReactComponent as OpportunityIcon } from "../assets/home/opportunity-icon.svg";
import { ReactComponent as CheckMark } from "../assets/commonIcons/checkMark.svg";
import { ReactComponent as CloseIcon } from "../assets/commonIcons/closeIcon.svg";
import { ReactComponent as Search } from "../assets/commonIcons/search.svg";
import { ReactComponent as SortAsc } from "../assets/commonIcons/sortAsc.svg";
import { ReactComponent as SortDsc } from "../assets/commonIcons/sortDsc.svg";
import { ReactComponent as ProfileImage } from "../assets/images/profile-image.svg";
import bidOpportunity from "../assets/home/bid-opportunity.svg";
//AUTH
import { ReactComponent as HeaderLogo } from "../assets/auth/header-logo.svg";
import { ReactComponent as EmailIcon } from "../assets/formIcons/email.svg";
import { ReactComponent as PasswordIcon } from "../assets/formIcons/password.svg";
import { ReactComponent as UsernameIcon } from "../assets/formIcons/username.svg";
import { ReactComponent as PhoneIcon } from "../assets/formIcons/phone.svg";
import { ReactComponent as Organization } from "../assets/formIcons/organization.svg";
import { ReactComponent as Industry } from "../assets/formIcons/industry.svg";
import { ReactComponent as Location } from "../assets/formIcons/location.svg";
import { ReactComponent as Camera } from "../assets/commonIcons/camera.svg";
import { ReactComponent as ThreeDots } from "../assets/commonIcons/threeDots.svg";
import { ReactComponent as UserAvatar } from "../assets/bidDetail/Avatar.svg";
import { ReactComponent as EditIcon } from "../assets/bidDetail/editIcon.svg";

import { ReactComponent as Delete } from "../assets/commonIcons/delete.svg";
import EyeClose from "../assets/formIcons/eyeClose.svg";
import EyeOpen from "../assets/formIcons/eyeOpen.svg";
import LoginImage from "../assets/auth/login-image.png";
import ForgotPassImage from "../assets/auth/forgot-pass-image.png";
import ResetPassImage from "../assets/auth/reset-pass-image.png";
import SignupImage1 from "../assets/auth/sign-up-image1.png";
import SignupImage2 from "../assets/auth/sign-up-image2.png";
// Common Icons
import { ReactComponent as LeftArrow } from "../assets/commonIcons/left-arrow.svg";
import { ReactComponent as DocumentIcon } from "../assets/commonIcons/document-icon.svg";
import { ReactComponent as DownloadIcon } from "../assets/commonIcons/download-icon.svg";
import { ReactComponent as DocIcon } from "../assets/commonIcons/docIcon.svg";
import Upsun from "../assets/images/upsun.png";

import AccountVerification from "../assets/auth/accountVerification.png";
import EmailVerification from "../assets/auth/emailVerification.png";

export default {
  ErrorThumbnail,
  BidsIcon,
  MyBidsIcon,
  DashboardIcon,
  ProfileIcon,
  SubscriptionIcon,
  upLogo,
  NotificationIcon,
  Mainlogo,
  addIcon,
  hamburger,
  onGoingIcon,
  activeIcon,
  completeIcon,
  WhyNotificationIcon,
  BetterBidIcon,
  OpportunityIcon,
  bidOpportunity,
  CheckMark,
  CloseIcon,
  //AUTH
  HeaderLogo,
  LoginImage,
  PasswordIcon,
  EmailIcon,
  EyeClose,
  EyeOpen,
  ForgotPassImage,
  ResetPassImage,
  SignupImage1,
  SignupImage2,
  // COMMON ICONS
  LeftArrow,
  DocumentIcon,
  DownloadIcon,
  // Images
  Upsun,

  UsernameIcon,
  PhoneIcon,
  AccountVerification,
  EmailVerification,
  Camera,
  Organization,
  Location,
  Industry,
  DocIcon,
  Search,
  ThreeDots,
  UserAvatar,
  EditIcon,

  Delete,
  ProfileImage,
  SortAsc,
  SortDsc,
};
