import React from "react";
import "./styles.scss";
import {
  CommonButton,
  CommonInputField,
  CommonSwitch,
  CommonTextAreaField,
  CommonTextField,
  DocUploader,
} from "../../../components/common";
import { Col, Form, Row, Space, Switch } from "antd";
import { Images } from "../../../theme";
import { inputFieldRule } from "../../../utils";
import { useState } from "react";
import { CreateBidForm } from "../../../components";
import { useParams } from "react-router-dom";
const CreateBid = () => {
  //CONST VALS
  const { id } = useParams();
  return (
    <section className="dashboard create-bid-wrapper">
      <CommonTextField
        text={`${id ? "Edit" : "Create"} Bid`}
        fontFamily="seventyTwoBold"
        fontSize={"34px"}
        color="#021349"
        mb={"12px"}
        lineHeight="42px"
      />
      <CreateBidForm />
    </section>
  );
};

export default CreateBid;
