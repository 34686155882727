import React, { useState } from "react";
import "../styles.scss";
import {
  CommonButton,
  CommonInputField,
  CommonTable,
  CommonTextAreaField,
  CommonTextField,
} from "../../../../../../components/common";
import { Checkbox, Col, Form, Row, Space } from "antd";
import { TABLE_DATA } from "../../../../../../constants";
import { creditCardValidator, inputFieldRule } from "../../../../../../utils";

function Step2({ isHidden, handleNextStep }) {
  //STATES
  const [noSubContractors, setNoSubContractors] = useState(false);

  //CONST VALS
  const [contractorForm] = Form.useForm();

  //HANDLERS
  const handleStep = () => {
    if (!noSubContractors) {
      contractorForm.validateFields();
      return;
    }
    handleNextStep(3);
  };

  const renderTableText = (text) => (
    <CommonTextField
      text={text}
      fontFamily={"seventyTwoRegular"}
      fontSize={"12px"}
      lineHeight={"13.8px"}
      color={"rgba(2, 19, 73, 1)"}
    />
  );

  const renderTableTotal = (text) => (
    <Space>
      <CommonTextField
        text={text}
        fontFamily={"seventyTwoRegular"}
        fontSize={"12px"}
        lineHeight={"13.8px"}
        color={"rgba(2, 19, 73, 1)"}
      />
      <CommonTextField
        text={"Edit"}
        fontFamily={"seventyTwoRegular"}
        fontSize={"12px"}
        lineHeight={"13.8px"}
        color={"rgba(2, 19, 73, 1)"}
      />
    </Space>
  );

  const columns = [
    {
      title: "Table",
      dataIndex: "table",
      key: "table",
      render: renderTableText,
    },
    {
      title: "# of Rows",
      dataIndex: "noOfRows",
      key: "noOfRows",
      align: "center",
      render: renderTableText,
    },
    {
      title: "# of Required Rows",
      dataIndex: "noOfRequiredRows",
      key: "noOfRequiredRows",
      align: "center",
      render: renderTableText,
    },
    {
      title: "# of Optional Rows",
      dataIndex: "noOfOptionalRows",
      key: "noOfOptionalRows",
      align: "center",
      render: renderTableText,
    },
    {
      title: "Mandatory",
      dataIndex: "mandatory",
      key: "mandatory",
      align: "center",
      render: renderTableText,
    },
    {
      title: "Sub Total",
      dataIndex: "subTotal",
      key: "subTotal",
      align: "center",
      render: renderTableTotal,
    },
  ];

  return (
    <div className={`${isHidden ? "hidden" : ""} `}>
      <Row>
        <Col lg={22} style={{ marginTop: 15 }}>
          <CommonTextField
            text={
              "All references stated shall be for the same or similar scope as the one described in this Bid. For newly formed business entity including, corporations, partnerships and sole proprietors or a Contractor teaming arrangement you shall state below in the Client Column that you were not the “Contractor” for the named project and should state whose past experience on the named project is relevant to that reference."
            }
            color={"rgba(2, 19, 73, 1)"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontFamily={"seventyTwoRegular"}
          />
        </Col>
      </Row>

      <div className="tableWrapper">
        <CommonTable columns={columns} dataSource={TABLE_DATA} />
      </div>

      <div className="refenceFormWrapper">
        <CommonTextField
          text={"References"}
          fontSize={"24px"}
          lineHeight={"27.6px"}
          color={"rgba(2, 19, 73, 1)"}
          fontFamily={"seventyTwoSemiBold"}
          mb={"20px"}
        />

        <Form className="stepDetailForm">
          <Row gutter={[12, 0]}>
            <Col span={12}>
              <CommonInputField
                placeholder={"Client/Company"}
                rules={inputFieldRule({
                  name: "Client/Company",
                  isRequired: false,
                  isMax: true,
                  max: 30,
                })}
                name="clientCompany"
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Length of Time in Business & Core Competencies"}
                rules={inputFieldRule({
                  name: "Lenght of Time",
                  isRequired: false,
                  isMax: true,
                  max: 30,
                })}
                name="lengthOfTime"
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Contact Name*"}
                rules={inputFieldRule({
                  name: "Contact Name*",
                  isMax: true,
                  max: 30,
                })}
                name="contactName"
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Phone Number*"}
                rules={inputFieldRule({
                  name: "Phone",
                  isMax: true,
                  max: 20,
                })}
                name="phone"
                onKeyDown={(e) => creditCardValidator(e, "card", 20)}
              />
            </Col>
            <Col span={24}>
              <CommonInputField
                placeholder={"Email Address*"}
                rules={inputFieldRule({
                  name: "Email Address*",
                  isEmail: true,
                  isMax: true,
                  max: 20,
                })}
                name="emailAddress"
              />
            </Col>
            <Col span={24}>
              <CommonTextAreaField
                placeholder={"Description"}
                rules={inputFieldRule({
                  name: "Description",
                  isRequired: false,
                })}
                name="description"
                height={"149px"}
              />
            </Col>
            <Col span={24}>
              <div className="buttonWrapper">
                <CommonButton
                  text={"Add Row"}
                  htmlType="submit"
                  classname={"button"}
                  width="200px"
                  height={"56px"}
                  fontSize={"18px"}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="tableWrapper">
        <CommonTextField
          text={"Sub-Contractors"}
          fontFamily={"seventyTwoSemiBold"}
          fontSize={"20px"}
          lineHeight={"23px"}
        />
        <CommonTextField
          text={`The Bidder shall state all Subcontractor(s) and type of Work proposed to be used for this project. Bidders shall not indicate “TBD” (To Be Determined) or “TBA” (To Be Announced) or similar wording and shall not indicate multiple choices of Subcontractor names for any Subcontractor category in their list of Subcontractors.`}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontFamily={"seventyTwoRegular"}
          mt={"15px"}
        />
        <CommonTextField
          text={`The Bidder shall state only one (1) subcontractor for each type of work Bidder(s) shall upon request by the Owner produce a list of references for all or any proposed Subcontractors within three (3) business days.`}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontFamily={"seventyTwoRegular"}
          mt={"15px"}
          mb={"30px"}
        />
        <CommonTable columns={columns} dataSource={TABLE_DATA} />
      </div>

      <div style={{ marginTop: 20 }}>
        <CommonTextField
          text={"Subcontractor -Names and References if Applicable"}
          fontSize={"18px"}
          lineHeight={"20.7px"}
          color={"rgba(2, 19, 73, 1)"}
          fontFamily={"seventyTwoRegular"}
          mb={"10px"}
        />

        <Form form={contractorForm} className="stepDetailForm">
          <Row gutter={[12, 0]}>
            <Col
              className="checkbox-parent"
              span={24}
              style={{ marginBottom: "15px" }}
            >
              <Checkbox
                checked={noSubContractors}
                onChange={() => setNoSubContractors(!noSubContractors)}
              />
              <CommonTextField
                text={
                  'By clicking here I confirm that there are no Subcontractor(s) and the Bidder shall perform the project with their “OWN FORCES".'
                }
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Company*"}
                rules={inputFieldRule({
                  name: "Client/Company",
                  isMax: true,
                  max: 30,
                })}
                name="clientCompany"
                disabled={noSubContractors}
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Name*"}
                rules={inputFieldRule({
                  name: "Contact Name",
                  isMax: true,
                  max: 30,
                })}
                name="contactName"
                disabled={noSubContractors}
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Phone Contact Information*"}
                rules={inputFieldRule({
                  name: "Lenght of Time",
                  isMax: true,
                  max: 30,
                })}
                name="lengthOfTime"
                disabled={noSubContractors}
              />
            </Col>
            <Col span={12}>
              <CommonInputField
                placeholder={"Previous Projects*"}
                rules={inputFieldRule({
                  name: "Phone",
                  isMax: true,
                  max: 20,
                })}
                name="phone"
                disabled={noSubContractors}
              />
            </Col>
            <Col span={24}>
              <CommonInputField
                placeholder={"Address*"}
                rules={inputFieldRule({
                  name: "Email Address",
                  isEmail: true,
                  isMax: true,
                  max: 20,
                })}
                name="emailAddress"
                disabled={noSubContractors}
              />
            </Col>
            <Col span={24}>
              <CommonTextAreaField
                placeholder={"Reference*"}
                rules={inputFieldRule({
                  name: "Description",
                })}
                name="description"
                height={"149px"}
                disabled={noSubContractors}
              />
            </Col>
            <Col span={24}>
              <div className="buttonWrapper">
                <CommonButton
                  text={"Add Row"}
                  htmlType="submit"
                  classname={"button"}
                  width="200px"
                  height={"56px"}
                  fontSize={"18px"}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="separator"></div>

      <div className="buttonWrapper">
        <CommonButton
          text={"Continue"}
          classname={"button"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={handleStep}
        />
      </div>
    </div>
  );
}

export default Step2;
