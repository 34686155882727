import React, { useState } from "react";
import {
  CommonButton,
  CommonModal,
  CommonTable,
  CommonTextField,
} from "../../../../../components/common";
import { Images } from "../../../../../theme";
import {
  BIDS_DETAIL,
  BRANCH_TABlE_DATA,
  EDIT_BID_ROUTE,
  OPEN_BIDS_DETAIL,
} from "../../../../../constants";
import { Space } from "antd";
import CommonThreeDotMenu from "../../../../../components/common/CommonThreeDotMenu";
import { replaceValInString, toastAlert } from "../../../../../utils";
import { useNavigate } from "react-router-dom";

function MoreBidsSection() {
  //STATES
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);

  //CONST VALS
  const navigate = useNavigate();

  //CUSTOM COMPONENTS
  const renderServiceColumn = (text) => (
    <CommonTextField text={text} className={"light-text"} fontWeight={700} />
  );

  const renderStatusColumn = () => (
    <div className={`statusButton `}>
      <CommonTextField
        text={"Open"}
        className={"text-11"}
        fontWeight={700}
        textTransform="capitalize"
      />
    </div>
  );

  const renderActionColumn = (_, data) => (
    <Space>
      <CommonButton
        text={"View Details"}
        background={"rgba(197, 222, 251, 1)"}
        borderRadius={"12px"}
        color={"rgba(0, 112, 242, 1)"}
        fontFamily={"seventyTwoSemiBold"}
        fontSize={"14px"}
      />
      <CommonThreeDotMenu
        onEdit={() =>
          navigate(replaceValInString(EDIT_BID_ROUTE, { ":id": data.key }))
        }
        onDelete={() => setDeleteModalPreview(true)}
      />
    </Space>
  );

  const renderNameColumn = (text) => (
    <CommonTextField
      text={text}
      fontWeight={700}
      className="c-p"
      onClick={() =>
        navigate(replaceValInString(BIDS_DETAIL, { ":id": text.key }))
      }
    />
  );
  //CONST VALS
  const columns = [
    {
      title: "Bid Name",
      dataIndex: "bidName",
      key: "bidName",
      render: renderNameColumn,
      sorter: {
        compare: (a, b) => a.bidName.localeCompare(b.bidName),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Published Date",
      dataIndex: "publishedDate",
      key: "publishedDate",
      render: renderServiceColumn,
      sorter: {
        compare: (a, b) =>
          new Date(a.publishedDate) - new Date(b.publishedDate),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Closing Date",
      dataIndex: "closingDate",
      key: "closingDate",
      render: renderServiceColumn,
      sorter: {
        compare: (a, b) => new Date(a.closingDate) - new Date(b.closingDate),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: renderServiceColumn,
      sorter: {
        compare: (a, b) => a.organization.localeCompare(b.organization),
        multiple: 1,
      },
      sortIcon: ({ sortOrder }) =>
        sortOrder ? (
          sortOrder === "ascend" ? (
            <Images.SortDsc />
          ) : (
            <></>
          )
        ) : (
          <Images.SortAsc />
        ),
    },
    {
      title: "Status",
      key: "action",
      width: 120,
      align: "center",
      render: renderStatusColumn,
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      align: "center",
      render: renderActionColumn,
    },
  ];
  return (
    <div className="MoreBitContentWrapper">
      <img src={Images.Upsun} />

      <CommonTextField
        text={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        }
        color={"rgba(2, 19, 73, 1)"}
        fontFamily={"seventyTwoRegular"}
        fontSize={"14px"}
        lineHeight={"20px"}
        mt={"10px"}
      />
      <CommonTextField
        text={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        }
        color={"rgba(2, 19, 73, 1)"}
        fontFamily={"seventyTwoRegular"}
        fontSize={"14px"}
        lineHeight={"20px"}
        mt={"20px"}
      />

      <div className="moreBidTableSection">
        <CommonTable columns={columns} dataSource={BRANCH_TABlE_DATA} />
      </div>
      <CommonModal
        isModalVisible={deleteModalPreview}
        setIsModalVisible={setDeleteModalPreview}
        discription="Do you want to delete this bid?"
        onConfirm={() => {
          setDeleteModalPreview(false);
          toastAlert("Bid deleted successfully.");
        }}
      ></CommonModal>
    </div>
  );
}

export default MoreBidsSection;
