import React, { useState } from "react";
import "./styles.scss";
import Images from "../../../../theme/Images";
import {
  CommonTextField,
  CommonButton,
  CommonTable,
  CommonModal,
} from "../../../../components/common";
import { Col, Row } from "antd";
import {
  BidsTable,
  SellerBidSubmission,
  YourBid,
} from "../../../../components";
import {
  BRANCH_TABlE_DATA,
  EDIT_BID_ROUTE,
  ONGOING_TABlE_DATA,
  STEPS_DETAIL,
} from "../../../../constants";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MoreBidsSection from "./partials/moreBids";
import CommonBidData from "./partials/commonBidData";
import CommonThreeDotMenu from "../../../../components/common/CommonThreeDotMenu";
import { replaceValInString, toastAlert } from "../../../../utils";

function BidDetails() {
  //STATES
  const [currentBidTabActive, setCurrentBidTabActive] = useState(true);
  const [deleteModalPreview, setDeleteModalPreview] = useState(false);
  //CONST VALS
  const { id } = useParams();
  const navigate = useNavigate();
  const data = ONGOING_TABlE_DATA.find((x) => x.key === id);
  //REDUX DATA
  const { isSeller } = useSelector((state) => state?.user);
  //HANDLERS
  const activeTabFunction = (val) => {
    setCurrentBidTabActive(val);
  };

  return (
    <div className="bidDetailMainWrapper">
      <div className="backArrowWrapper">
        <Images.LeftArrow className="c-p" onClick={() => navigate(-1)} />
        <CommonTextField
          text={"Bid Details"}
          fontSize={"34px"}
          color={"rgba(2, 19, 73, 1)"}
          lineHeight={"42px"}
          letterSpacing={"-2%"}
          fontFamily={"seventyTwoBold"}
        />
      </div>

      {isSeller && (
        <div className="tabsWrapper">
          <div
            className={`tabsItemWrapper tabsItemWrapper1 ${
              currentBidTabActive === true ? "activeTab" : ""
            }`}
            onClick={() => activeTabFunction(true)}
          >
            <CommonTextField
              text={"Current Bit"}
              fontSize={"16px"}
              lineHeight={"18.4px"}
              fontFamily={"seventyTwoSemiBold"}
              textAlign={"center"}
            />
          </div>
          <div
            className={`tabsItemWrapper tabsItemWrapper2 ${
              currentBidTabActive === false ? "activeTab" : ""
            }`}
            onClick={() => activeTabFunction(false)}
          >
            <CommonTextField
              text={"More Bits"}
              fontSize={"16px"}
              lineHeight={"18.4px"}
              fontFamily={"seventyTwoSemiBold"}
              textAlign={"center"}
            />
          </div>
        </div>
      )}

      <div
        className={`${isSeller ? "" : "sellerMainWrapper"} ContentMainWrapper`}
      >
        {currentBidTabActive && (
          <div className="CurrentBitContentWrapper">
            {isSeller && (
              <div className="bid-header">
                <img src={Images.Upsun} />
                <div className="bid-options-wrapper">
                  {data?.category === "history" && (
                    <span
                      className={`bid-status ${
                        data?.isCancelled ? "cancel" : "complete"
                      }`}
                    >
                      {data?.isCancelled ? "Cancelled" : "Completed"}
                    </span>
                  )}
                  <CommonThreeDotMenu
                    onEdit={() =>
                      navigate(
                        replaceValInString(EDIT_BID_ROUTE, { ":id": data.key })
                      )
                    }
                    onDelete={() => setDeleteModalPreview(true)}
                  />
                </div>
              </div>
            )}
            <CommonBidData id={data?.category} isSeller={isSeller} />

            {isSeller &&
              (data?.category === "open" ? (
                <></>
              ) : data?.category === "ongoing" ? (
                <YourBid ongoing={true} />
              ) : data?.category === "active" ? (
                <YourBid />
              ) : (
                <YourBid isCancelled={data?.isCancelled} />
              ))}

            {!isSeller ? (
              data?.category === "ongoing" ? (
                <SellerBidSubmission />
              ) : data?.category === "active" ? (
                <SellerBidSubmission active={true} />
              ) : (
                <SellerBidSubmission
                  history={true}
                  isCancelled={data?.isCancelled}
                />
              )
            ) : (
              data?.category === "open" && (
                <div className="buttonWrapper">
                  {/* <CommonButton
                    text={"Submission"}
                    background={"rgba(3, 25, 70, 1)"}
                    fontSize={"16px"}
                    classname={"buttons"}
                    fontFamily={"seventyTwoBold"}
                  /> */}
                  <CommonButton
                    text={"Send Bid"}
                    fontSize={"16px"}
                    classname={"buttons"}
                    fontFamily={"seventyTwoBold"}
                    onClick={() =>
                      navigate(
                        replaceValInString(STEPS_DETAIL, { ":id": data.key })
                      )
                    }
                  />
                </div>
              )
            )}
          </div>
        )}

        {!currentBidTabActive && <MoreBidsSection />}
      </div>
      <CommonModal
        isModalVisible={deleteModalPreview}
        setIsModalVisible={setDeleteModalPreview}
        discription="Do you want to delete this bid?"
        onConfirm={() => {
          setDeleteModalPreview(false);
          toastAlert("Bid deleted successfully.");
          navigate(-1);
        }}
      ></CommonModal>
    </div>
  );
}

export default BidDetails;
