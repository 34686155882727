import React from "react";
import { useState } from "react";
import { Images } from "../../../theme";
import { imageValidation } from "../../../utils";
import "./styles.scss";
function ImageUploader({ setImage, setPreviewImage, isIcon, iconClassName }) {
  const [image, setImg] = useState(null);
  const handleChange = (e) => {
    // if (isLoading) {
    //   return;
    // }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
    // if (file) {
    //   if (imageValidation(file, setErrorToggle)) {
    //     const data = new FormData();
    //     data.append("file", file);
    //     data.append("directory", directory);
    //     uploadImage({
    //       payload: data,
    //       logic({ key, url }) {
    //         setImage(key);
    //         setPreviewImage(url);
    //       },
    //     });
    //   }
    // }
  };
  return (
    <div className="image-uploader">
      <input
        type="file"
        onChange={handleChange}
        value=""
        accept=".jpg, .jpeg, .png"
      />
      {image ? <img src={image} /> : <Images.Camera />}
    </div>
  );
}

export default ImageUploader;
