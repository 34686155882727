import { Avatar, Space } from "antd";
import React, { useState } from "react";
import { Images } from "../../../../theme";
import { CommonButton, CommonTextField } from "../../../common";
import "./styles.scss";
import CancelBidModal from "../cancelBidModal";
import { toastAlert } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import ConfirmBidModal from "../confirmBidModal";
import SellerSubmissionModal from "../sellerSubModal";

const SellerBidSubmission = ({ active, history, isCancelled }) => {
  //STATES
  const [cancelModalPreview, setCancelModalPreview] = useState(false);
  const [confirmModalPreview, setConfirmModalPreview] = useState(false);
  const [subDetailModalPreview, setSubDetailModalPreview] = useState(false);

  //CONST VALS
  const navigate = useNavigate();

  //HANDLERS
  const cancelModalPreviewHandler = () => {
    setCancelModalPreview(!cancelModalPreview);
  };
  const confirmModalPreviewHandler = () => {
    setConfirmModalPreview(!confirmModalPreview);
  };
  const subDetailModalPreviewHandler = () => {
    setSubDetailModalPreview(!subDetailModalPreview);
  };
  const handleCancelBid = () => {
    toastAlert("Bid cancelled successfully.");
    cancelModalPreviewHandler();
    navigate(-1);
  };
  const handleConfirmBid = () => {
    toastAlert("Bid confirmed successfully.");
    confirmModalPreviewHandler();
    navigate(-1);
  };
  return (
    <div className="sellerbid-parent">
      <CommonTextField
        text={"Seller Bid Submissions"}
        fontFamily={"seventyTwoSemiBold"}
        fontSize={"24px"}
        lineHeight={"36px"}
        color={"rgba(2, 19, 73, 1)"}
      />
      <div className="list-parent">
        {new Array(active || history ? 1 : 5).fill(1).map((t, key) => (
          <div key={key} className="sellerbid-card">
            <Space>
              <Avatar size={51} src={<Images.UserAvatar />} />
              <Space size={1} direction="vertical">
                <CommonTextField
                  fontFamily={"seventyTwoRegular"}
                  className={""}
                  fontSize={"14px"}
                  text="Ronald Richards"
                  lineHeight={"10px"}
                />
                <CommonTextField
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"12px"}
                  className={"light-text"}
                  text="Senior UI UX Designer"
                />
              </Space>
            </Space>
            <CommonButton
              text={"View Details"}
              background={"rgba(3, 25, 70, 1)"}
              fontFamily={"seventyTwoBold"}
              fontSize={"16px"}
              onClick={subDetailModalPreviewHandler}
            />
          </div>
        ))}
      </div>
      {active && (
        <div className="buttonWrapper">
          <CommonButton
            text={"Mark as Cancel"}
            background={"#FF8484"}
            fontSize={"18px"}
            classname={"buttons"}
            fontFamily={"seventyTwoBold"}
            onClick={cancelModalPreviewHandler}
          />
          <CommonButton
            text={"Mark as Completed"}
            fontSize={"18px"}
            classname={"buttons"}
            fontFamily={"seventyTwoBold"}
            onClick={confirmModalPreviewHandler}
          />
        </div>
      )}

      {history && isCancelled && (
        <div className="cancel-reason">
          <CommonTextField
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            className={"light-text"}
            text="Cancellation Reason"
          />
          <CommonTextField text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
        </div>
      )}
      <CancelBidModal
        preview={cancelModalPreview}
        handleClose={cancelModalPreviewHandler}
        handleConfirm={handleCancelBid}
      />
      <ConfirmBidModal
        preview={confirmModalPreview}
        handleClose={confirmModalPreviewHandler}
        handleConfirm={handleConfirmBid}
      />
      <SellerSubmissionModal
        preview={subDetailModalPreview}
        handleClose={subDetailModalPreviewHandler}
      />
    </div>
  );
};

export default SellerBidSubmission;
