import React from "react";
import { Col, Form, Modal, Row } from "antd";
import "./styles.scss";
import { CommonButton, CommonInputField, CommonTextField } from "../../common";
import { Images } from "../../../theme";
import { creditCardValidator, inputFieldRule } from "../../../utils";

const BuySubscriptionModal = ({ handleClose, handleFinish, preview }) => {
  return (
    <Modal
      destroyOnClose
      open={preview}
      centered
      footer={null}
      width={633}
      className="buy-sub-modal"
      closeIcon={false}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="close-btn" onClick={handleClose}>
            <Images.CloseIcon />
          </div>
          <div className="title">
            <h3>Buy Subscriptions</h3>
          </div>
          <Form onFinish={handleFinish} className="payment-form">
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <div className="package-details">
                  <CommonTextField
                    text={"Package 1 | $69.99/m"}
                    color="#0070F2"
                    fontWeight={"700"}
                    fontFamily="Lato"
                    fontSize={"28px"}
                  />
                </div>
              </Col>
              <Col span={12}>
                <CommonInputField
                  placeholder={"First Name"}
                  rules={inputFieldRule({
                    name: "First name",
                    isMax: true,
                    max: 30,
                  })}
                  name="firstName"
                />
              </Col>
              <Col span={12}>
                <CommonInputField
                  placeholder={"Last Name"}
                  rules={inputFieldRule({
                    name: "Last name",
                    isMax: true,
                    max: 30,
                  })}
                  name="lastName"
                />
              </Col>
              <Col span={12}>
                <CommonInputField
                  placeholder={"Card Number"}
                  rules={inputFieldRule({
                    name: "Card number",
                    // isMax: true,
                    // max: 12,
                    // isCardNumber: true,
                  })}
                  name="cardNumber"
                  onKeyDown={(e) => creditCardValidator(e, "card")}
                />
              </Col>
              <Col span={12}>
                <CommonInputField
                  placeholder={"Expiration"}
                  rules={inputFieldRule({
                    name: "Expiration date",
                    // isMax: true,
                    // max: 7,
                    // isCardExpiry: true,
                  })}
                  name="cardExpiration"
                  onKeyDown={(e) => creditCardValidator(e, "date")}
                />
              </Col>
              <Col span={12}>
                <CommonInputField
                  placeholder={"CVV"}
                  rules={inputFieldRule({
                    name: "CVV",
                    isMax: true,
                    max: 3,
                    isNumberOnly: true,
                  })}
                  name="cvv"
                  onKeyDown={(e) => creditCardValidator(e, "card", 3)}
                />
              </Col>
              <Col span={12}>
                <CommonInputField
                  placeholder={"Postal Code"}
                  rules={inputFieldRule({
                    name: "Postal code",
                    isAlphabetsAndNumber: true,
                    isMax: true,
                    max: 20,
                  })}
                  name="postalCode"
                />
              </Col>
              <Col span={24}>
                <CommonButton
                  text={"Pay Now"}
                  width="100%"
                  borderRadius={"12px"}
                  htmlType="submit"
                />
              </Col>
            </Row>
          </Form>
          {/* <div className="modal-footer">
            <CommonButton text={"Pay Now"} width="100%" borderRadius={"12px"} />
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default BuySubscriptionModal;
