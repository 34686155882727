import React, { useState } from "react";
import "./styles.scss";
import Images from "../../../../theme/Images";
import { CommonTextField } from "../../../../components/common";
import { Col, Row } from "antd";
import { BRANCH_TABlE_DATA, TABLE_DATA } from "../../../../constants";
import { Step0, Step1, Step2, Step3, Step4, Step5, Step6 } from "./partials";
import { useNavigate } from "react-router-dom";
import { toastAlert } from "../../../../utils";

function StepsDetails() {
  //STATES
  const [step, setStep] = useState(0);
  //CONST VALS
  const navigate = useNavigate();
  //HANDLERS
  const handleNextStep = (val) => {
    if (val === 7) {
      toastAlert("Bid submitted successfully.");
      navigate(-1);
      return;
    }
    setStep(val);
  };
  return (
    <div className="stepDetailMainWrapper">
      <div className="backArrowWrapper">
        <Images.LeftArrow className="c-p" onClick={() => navigate(-1)} />
        <CommonTextField
          text={"Bid Details"}
          fontSize={"34px"}
          color={"rgba(2, 19, 73, 1)"}
          lineHeight={"42px"}
          letterSpacing={"-2%"}
          fontFamily={"seventyTwoBold"}
        />
      </div>

      <div className="stepDetailWrapper">
        {step === 0 ? (
          <Step0 isHidden={step !== 0} handleNextStep={handleNextStep} />
        ) : (
          <div>
            <div className="stepsWrapper">
              <div className="stepsItemWrapper" onClick={() => setStep(1)}>
                <div className="stepCountWrap">
                  <CommonTextField
                    text={"1"}
                    className={"stepCountText"}
                    fontFamily={"seventyTwoSemiBold"}
                  />
                </div>
                <CommonTextField
                  text={"Schedule of prices"}
                  className={"stepDescText showInWeb"}
                  fontFamily={"seventyTwoRegular"}
                  wordBreak="none"
                />
              </div>

              <div className={`stepsItemWrapper `} onClick={() => setStep(2)}>
                <div className={`stepCountWrap ${step >= 2 ? "" : "inactive"}`}>
                  <CommonTextField
                    text={"2"}
                    className={"stepCountText"}
                    fontFamily={"seventyTwoSemiBold"}
                  />
                </div>
                <CommonTextField
                  text={"Ref & Subcontractors"}
                  className={"stepDescText showInWeb"}
                  fontFamily={"seventyTwoRegular"}
                  wordBreak="none"
                />
              </div>

              <div className="stepsItemWrapper" onClick={() => setStep(3)}>
                <div className={`stepCountWrap ${step >= 3 ? "" : "inactive"}`}>
                  <CommonTextField
                    text={"3"}
                    className={"stepCountText"}
                    fontFamily={"seventyTwoSemiBold"}
                    wordBreak="none"
                  />
                </div>
                <CommonTextField
                  text={"Documents"}
                  className={"stepDescText showInWeb"}
                  fontFamily={"seventyTwoRegular"}
                  wordBreak="none"
                />
              </div>

              <div className="stepsItemWrapper" onClick={() => setStep(4)}>
                <div className={`stepCountWrap ${step >= 4 ? "" : "inactive"}`}>
                  <CommonTextField
                    text={"4"}
                    className={"stepCountText"}
                    fontFamily={"seventyTwoSemiBold"}
                  />
                </div>
                <CommonTextField
                  text={"Addenda, T&C"}
                  className={"stepDescText showInWeb"}
                  fontFamily={"seventyTwoRegular"}
                  wordBreak="none"
                />
              </div>

              <div className="stepsItemWrapper" onClick={() => setStep(5)}>
                <div className={`stepCountWrap ${step >= 5 ? "" : "inactive"}`}>
                  <CommonTextField
                    text={"5"}
                    className={"stepCountText"}
                    fontFamily={"seventyTwoSemiBold"}
                  />
                </div>
                <CommonTextField
                  text={"Preview Bid"}
                  className={"stepDescText showInWeb"}
                  fontFamily={"seventyTwoRegular"}
                  wordBreak="none"
                />
              </div>

              <div className="stepsItemWrapper" onClick={() => setStep(6)}>
                <div className={`stepCountWrap ${step >= 6 ? "" : "inactive"}`}>
                  <CommonTextField
                    text={"6"}
                    className={"stepCountText"}
                    fontFamily={"seventyTwoSemiBold"}
                  />
                </div>
                <CommonTextField
                  text={"Submit Bid"}
                  className={"stepDescText showInWeb"}
                  fontFamily={"seventyTwoRegular"}
                  wordBreak="none"
                />
              </div>
              <div className="stepsUnactiveLine"></div>
              <div
                className="stepsActiveLine"
                style={{
                  width: `${
                    step === 2
                      ? "34%"
                      : step === 3
                      ? "51%"
                      : step === 4
                      ? "68%"
                      : step === 5
                      ? "85%"
                      : step === 6
                      ? "85%"
                      : ""
                  }`,
                }}
              ></div>
            </div>

            <CommonTextField
              mb={"10px"}
              className={"showInMob"}
              text={
                step === 1
                  ? "Schedule of prices"
                  : step === 2
                  ? "Ref & Subcontractors"
                  : step === 3
                  ? "Documents"
                  : step === 4
                  ? "Addenda, T&C"
                  : step === 5
                  ? "Preview Bid"
                  : step === 6
                  ? "Submit Bid"
                  : ""
              }
              color={"rgba(2, 19, 73, 1)"}
              fontSize={"20px"}
              lineHeight={"23px"}
              fontFamily={"seventyTwoSemiBold"}
            />

            <div
              className={`stepDetailItemWrapper`}
              style={{
                minHeight: `${step === 6 ? "calc(100vh - 353px)" : ""}`,
              }}
            >
              <div className="alertWrap">
                <CommonTextField
                  text={
                    "Your bid has not been submitted. To submit your bid press the Submit Bid button."
                  }
                  color={"rgba(0, 112, 242, 1)"}
                  fontFamily={"seventyTwoRegular"}
                  fontSize={"14px"}
                  lineHeight={"16.1px"}
                />
              </div>
              {step !== 5 && (
                <>
                  <div className="currentBitWrap">
                    <CommonTextField
                      text={"Current Bid"}
                      fontSize={"24px"}
                      lineHeight={"27.6px"}
                      color={"rgba(2, 19, 73, 1)"}
                      fontFamily={"seventyTwoSemiBold"}
                    />

                    <div>
                      <CommonTextField
                        text={"Last updated by: Cameron Janvier"}
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        color={"rgba(2, 19, 73, 1)"}
                        fontFamily={"seventyTwoRegular"}
                        textAlign={"right"}
                      />
                      <CommonTextField
                        text={"Mon Jan 22, 2024 06:52:46"}
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        color={"rgba(2, 19, 73, 1)"}
                        fontFamily={"seventyTwoRegular"}
                        textAlign={"right"}
                      />
                    </div>
                  </div>
                  <Row style={{ marginTop: 20 }}>
                    <Col lg={14}>
                      <CommonTextField
                        text={
                          "2023-091 - Landscape Architect Services - Preferred Supplier(s)"
                        }
                        color={"rgba(2, 19, 73, 1)"}
                        fontSize={"20px"}
                        lineHeight={"23px"}
                        fontFamily={"seventyTwoSemiBold"}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Step1 isHidden={step !== 1} handleNextStep={handleNextStep} />
              <Step2 isHidden={step !== 2} handleNextStep={handleNextStep} />
              <Step3 isHidden={step !== 3} handleNextStep={handleNextStep} />
              <Step4 isHidden={step !== 4} handleNextStep={handleNextStep} />
              <Step5 isHidden={step !== 5} handleNextStep={handleNextStep} />
              <Step6 isHidden={step !== 6} handleNextStep={handleNextStep} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default StepsDetails;
