import React from "react";
import { PublicHeader } from "../../components";

const PublicSharedLayout = ({ children }) => {
  return (
    <section className="container">
      <PublicHeader />
      {children}
    </section>
  );
};

export default PublicSharedLayout;
