import React from "react";
import { Header, Sidebar } from "../../components";
import "./styles.scss";

const PrivateSharedLayout = ({ children }) => {
  return (
    <section className="private-wrapper">
      <Header />
      <Sidebar />
      <div className="private-content">{children}</div>
    </section>
  );
};

export default PrivateSharedLayout;
