import React from "react";
import { Col, Row, Checkbox, Radio, Space } from "antd";
import {
  CommonButton,
  CommonTable,
  CommonTextField,
} from "../../../../../../components/common";
import { TABLE_DATA_STEP_4 } from "../../../../../../constants";

function Step5({ isHidden, handleNextStep }) {
  const renderTableText = (text) => (
    <CommonTextField
      text={text}
      fontFamily={"seventyTwoRegular"}
      fontSize={"12px"}
      lineHeight={"13.8px"}
      color={"rgba(2, 19, 73, 1)"}
    />
  );
  const renderTableCheckBox = () => (
    <Checkbox disabled checked className="disabled"></Checkbox>
  );

  const columns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      render: renderTableText,
      width: 500,
    },
    {
      title:
        "I have reviewed the below addendum and attachments (if applicable)",
      dataIndex: "attachments",
      key: "attachments",
      align: "center",
      render: renderTableCheckBox,
      width: 400,
    },
    {
      title: "Pages",
      dataIndex: "pages",
      key: "pages",
      align: "center",
      render: renderTableText,
    },
  ];

  return (
    <div className={`stepDetailItemWrapper ${isHidden ? "hidden" : ""}`}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <CommonTextField
            text={"Vendor Details"}
            fontFamily={"seventyTwoSemiBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            mt={"30px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Company Name"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"Quad Industrial Group Ltd"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Contact"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"Cameron Janvier"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Email"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"info@quadind.ca"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col lg={8} xs={24}>
          <CommonTextField
            text={"Phone"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"780-900-1122"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col span={24}>
          <CommonTextField
            text={"Address"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"10180101 StreetEdmonton, Alberta T5J 3S4"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col span={24}>
          <CommonTextField
            text={"Does your company conduct business under any other name?"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"Alberta"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col span={24}>
          <CommonTextField
            text={"Submission Details"}
            fontFamily={"seventyTwoSemiBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            mt={"10px"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Created on:"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"Quad Industrial Group Ltd"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Submitted on:"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"None"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col lg={14} xs={24}>
          <CommonTextField
            text={"Submitted by:"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"None"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Email"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"None"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col lg={5} xs={24}>
          <CommonTextField
            text={"Transaction #:"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"None"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col lg={14} xs={24}>
          <CommonTextField
            text={"Submitter’s Ip Address"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"12px"}
            lineHeight={"13.8px"}
            color={"rgba(70, 94, 125, 0.5)"}
            mb="5px"
          />
          <CommonTextField
            text={"None"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"16.1px"}
            color={"rgba(2, 19, 73, 1)"}
          />
        </Col>

        <Col span={24}>
          <CommonTextField
            text={"Schedule of Prices"}
            fontFamily={"seventyTwoBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            mt={"10px"}
          />
        </Col>

        <Col lg={22} xs={24}>
          <CommonTextField
            text={
              "The Bidder hereby Bids and offers to enter into the Contract referred to and to supply and do all or any part of the Work which is set out or called for in this Bid, at the unit prices, and/or lump sums, hereinafter stated. HST is additional."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"13px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
          />
          <p
            style={{
              fontFamily: "seventyTwoRegular",
              fontSize: "13px",
              lineHeight: "20.98px",
              color: "rgba(2, 19, 73, 1)",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            * Denotes a <span style={{ fontWeight: "bold" }}>"MANDATORY"</span>{" "}
            field
          </p>
          <CommonTextField
            text={
              "Do not enter $0.00 dollars unless you are providing the line item at zero dollars to the Owner (unless otherwise specified)."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"13px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mb={"8px"}
          />

          <p
            style={{
              fontFamily: "seventyTwoRegular",
              fontSize: "13px",
              lineHeight: "20.98px",
              color: "rgba(2, 19, 73, 1)",
            }}
          >
            If the line item and/or table is{" "}
            <span style={{ fontWeight: "bold" }}>'NON-MANDATORY'</span> and you
            are not bidding on it, leave the table and/or line item blank.Do not
            enter a $0.00 dollar value.
          </p>
        </Col>

        <Col span={24}>
          <CommonTextField
            text={"Pricing Information - Landscape Architects"}
            fontFamily={"seventyTwoBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            mt={"10px"}
          />
        </Col>

        <Col span={24}>
          <div className="step5TableWrapper">
            <table style={{ width: "100%" }} className="step5Table">
              <tr>
                <th style={{ width: "8%" }}>Line Item</th>
                <th style={{ width: "23%" }}>Description</th>
                <th style={{ width: "23%" }}>Hourly Rate 2024*</th>
                <th style={{ width: "23%" }}>Hourly Rate 2025*</th>
                <th style={{ width: "23%" }}>Hourly Rate 2026*</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Landscape Architect</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
        </Col>

        <Col span={24}>
          <CommonTextField
            text={"Pricing Information - Additional Resources"}
            fontFamily={"seventyTwoBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            mt={"10px"}
          />
        </Col>

        <Col span={24}>
          <div className="step5TableWrapper">
            <table style={{ width: "100%" }} className="step5Table">
              <tr>
                <th style={{ width: "8%" }}>Line Item</th>
                <th style={{ width: "23%" }}>Description</th>
                <th style={{ width: "23%" }}>Hourly Rate 2024*</th>
                <th style={{ width: "23%" }}>Hourly Rate 2025*</th>
                <th style={{ width: "23%" }}>Hourly Rate 2026*</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Landscape Architect</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td>Landscape Architect</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td>Landscape Architect</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td>Landscape Architect</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
        </Col>

        <Col lg={22} xs={24}>
          <CommonTextField
            text={
              "All references stated shall be for the same or similar scope as the one described in this Bid."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"13px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
          />
          <CommonTextField
            text={
              "For newly formed business entity including, corporations, partnerships and sole proprietors or a Contractor teaming arrangement you shall state below in the Client Column that you were not the “Contractor” for the named project and should state whose past experience on the named project is relevant to that reference."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"13px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"10px"}
          />
        </Col>

        <Col span={24}>
          <CommonTextField
            text={"References"}
            fontFamily={"seventyTwoBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            mt={"10px"}
          />
        </Col>

        <Col span={24}>
          <div className="step5TableWrapper">
            <table style={{ width: "100%" }} className="step5Table">
              <tr>
                <th style={{ width: "8%" }}>Line Item</th>
                <th style={{ width: "16%" }}>Client/Company</th>
                <th style={{ width: "18%" }}>
                  Length of Time in Business & Core Competencies*
                </th>
                <th style={{ width: "16%" }}>Contact Name*</th>
                <th style={{ width: "16%" }}>Phone Number*</th>
                <th style={{ width: "16%" }}>Email Address*</th>
                <th style={{ width: "18%" }}>Description of Services*</th>
              </tr>
              <tr>
                <td>1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
        </Col>

        <Col span={24}>
          <CommonTextField
            text={"Project Team"}
            fontFamily={"seventyTwoBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            mt={"10px"}
          />
        </Col>

        <Col span={24}>
          <div className="step5TableWrapper">
            <table style={{ width: "100%" }} className="step5Table">
              <tr>
                <th style={{ width: "8%" }}>Line Item</th>
                <th style={{ width: "31%" }}>Description</th>
                <th style={{ width: "31%" }}>Hourly Rate 2024*</th>
                <th style={{ width: "31%" }}>Hourly Rate 2025*</th>
              </tr>
              <tr>
                <td>1</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
        </Col>

        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Sub-Contractors"}
            fontFamily={"seventyTwoSemiBold"}
            fontSize={"20px"}
            lineHeight={"23px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"13px"}
          />
          <CommonTextField
            text={
              "The Bidder shall state all Subcontractor(s) and type of Work proposed to be used for this project.  Bidders shall not indicate “TBD” (To Be Determined) or “TBA” (To Be Announced) or similar wording and shall not indicate multiple choices of Subcontractor names for any Subcontractor category in their list of Subcontractors."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"13px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"15px"}
          />
          <CommonTextField
            text={
              "The Bidder shall state only one (1) subcontractor for each type of work"
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"13px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"10px"}
          />
          <CommonTextField
            text={
              "Bidder(s) shall upon request by the Owner produce a list of references for all or any proposed Subcontractors within three (3) business days."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"13px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"10px"}
          />
        </Col>

        <Col span={24}>
          <CommonTextField
            text={"Subcontractor - Names and References if Applicable"}
            fontFamily={"seventyTwoBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            mt={"10px"}
            mb={"20px"}
          />
          <div className="checkbox-parent">
            <Checkbox checked disabled className="disabled" />
            <CommonTextField
              text={
                " By clicking here I confirm that there are no Subcontractor(s) and the Bidder shall perform the project with their “OWN FORCES”."
              }
            />
          </div>
        </Col>

        <Col span={24}>
          <div className="step5TableWrapper">
            <table style={{ width: "100%" }} className="step5Table">
              <tr>
                <th style={{ width: "8%" }}>Line Item</th>
                <th style={{ width: "16%" }}>Company*</th>
                <th style={{ width: "16%" }}>Name*</th>
                <th style={{ width: "16%" }}>Phone Contact Information*</th>
                <th style={{ width: "16%" }}>Address*</th>
                <th style={{ width: "16%" }}>Previous Projects*</th>
                <th style={{ width: "16%" }}>References*</th>
              </tr>
              <tr>
                <td>1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
        </Col>

        <Col lg={22} xs={24}>
          <CommonTextField
            text={"Documents"}
            fontFamily={"seventyTwoSemiBold"}
            fontSize={"20px"}
            lineHeight={"23px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"13px"}
          />
          <CommonTextField
            text={"Submittal 1 - Proposal * (mandatory)"}
            fontFamily={"seventyTwoRegular"}
            fontSize={"16px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"15px"}
          />
          <CommonTextField
            text={
              "Submittal 2 - Appendix C - Submission Form (completed) * (mandatory)"
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"16px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"8px"}
          />
          <CommonTextField
            text={
              "Submittal 3 - Health & Safety Questionnaire (completed) * (mandatory)"
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"16px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"8px"}
          />
          <CommonTextField
            text={
              "Submittal 4 - Other (additional information as required) (optional)"
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"16px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"8px"}
          />
        </Col>

        <Col span={24}>
          <CommonTextField
            text={"Addenda, Terms and Conditions"}
            fontFamily={"seventyTwoBold"}
            fontSize={"24px"}
            lineHeight={"27.6px"}
            mt={"10px"}
            mb={"20px"}
          />

          <div className="checkbox-parent">
            <Checkbox disabled checked className="disabled" />
            <CommonTextField
              text={
                " I/WE agree to be bound by the terms and conditions and have authority to bind the Corporation and submit this Bid on behalf of the Bidder."
              }
            />
          </div>

          <CommonTextField
            text={
              "Proponents must fullydisclose, in writing to the County on or before the Closing Date and Time ofthis Bid, the circumstances of any potential conflict of interest or what couldbe perceived as a possible conflict of interest if the Proponent were to becomea contracting party pursuant to this Bid. The County shall review anysubmissions by Proponents under this provision and may reject any Submissions where, in the sole opinion of County, the Proponent could be in a conflict ofinterest or could be perceived to be in a possible conflict of interestposition if the Proponent were to become a contracting party pursuant to this Bid."
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"15px"}
          />

          <Radio.Group
            name="radioGroup"
            style={{ marginTop: 15 }}
            defaultValue={1}
            disabled
            className="disabled"
          >
            <Radio value={1}>Yes</Radio>
            <Radio value={2}>No</Radio>
          </Radio.Group>

          <CommonTextField
            text={
              "The Bidder acknowledges and agrees that the addendum/addenda below form part of the Bid Document"
            }
            fontFamily={"seventyTwoRegular"}
            fontSize={"14px"}
            lineHeight={"20.98px"}
            color={"rgba(2, 19, 73, 1)"}
            mt={"15px"}
          />

          <p
            style={{
              fontFamily: "seventyTwoRegular",
              fontSize: "14px",
              lineHeight: "20.98px",
              color: "rgba(2, 19, 73, 1)",
              marginTop: "8px",
            }}
          >
            Please check the box in the column{" "}
            <span style={{ fontWeight: "bold" }}>
              "I have reviewed this addendum"
            </span>{" "}
            below to acknowledge each of the addenda.
          </p>
        </Col>

        <Col span={24} style={{ marginTop: 30 }} className="tableWrapper">
          <CommonTable columns={columns} dataSource={TABLE_DATA_STEP_4} />
        </Col>
      </Row>

      <div className="buttonWrapper">
        <CommonButton
          text={"Continue"}
          classname={"button"}
          width="200px"
          height={"56px"}
          fontSize={"18px"}
          onClick={() => handleNextStep(6)}
        />
      </div>
    </div>
  );
}

export default Step5;
