import { Row } from "antd";
import React from "react";
import { useState } from "react";
import { BuySubscriptionModal } from "../../../components";
import { CommonButton, CommonTextField } from "../../../components/common";
import { Images } from "../../../theme";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { loginSuccess } from "../../../redux/slicers/user";
import { CustomDispatch } from "../../../helpers";
import { DASHBOARD_ROUTE } from "../../../constants";
import { useSelector } from "react-redux";

function AuthSubscriptions() {
  const [loginRequest, loginLoader] = CustomDispatch(loginSuccess);

  const navigate = useNavigate();
  //STATES
  const [buyModalPreview, setBuyModalPreview] = useState(false);
  //REDUX DATA
  const { isSeller } = useSelector((state) => state.user);
  //HANDLERS
  const buyModalPreviewHandler = () => {
    setBuyModalPreview(!buyModalPreview);
  };

  const handleFinish = () => {
    console.log(isSeller, "isSeller");
    loginRequest({ payload: { isSeller } });

    navigate(DASHBOARD_ROUTE);
  };

  return (
    <div className="subscription-wrapper">
      <CommonTextField
        text={"Our Subscription"}
        color="#031946"
        fontSize={"34px"}
        fontWeight="600"
        textAlign={"center"}
        mb="15px"
      />
      <CommonTextField
        text={"Choose any subscriprion"}
        color="#465E7D"
        fontSize={"16px"}
        fontWeight="400"
        textAlign={"center"}
      />
      <div className="subscriptions-wrapper">
        {[1, 2, 3, 4].map((item, index) => (
          <div className="subscription-item" key={index}>
            <div className="sub-item-heading">
              <CommonTextField
                text={"Package 1"}
                color="#031946"
                fontSize={"24px"}
                fontWeight="600"
                textAlign={"center"}
                mb="10px"
              />
              <CommonTextField
                text={"$69.99/m"}
                color="#031946"
                fontSize={"20px"}
                fontWeight="400"
                textAlign={"center"}
              />
            </div>
            <div className="sub-description">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                <span key={index}>
                  <Images.CheckMark />
                  lorem ipsum emit service
                </span>
              ))}
            </div>
            <CommonButton text={"Buy Now"} onClick={buyModalPreviewHandler} />
          </div>
        ))}
      </div>
      <BuySubscriptionModal
        handleFinish={handleFinish}
        preview={buyModalPreview}
        handleClose={buyModalPreviewHandler}
      />
    </div>
  );
}

export default AuthSubscriptions;
