import { Switch } from "antd";
import React from "react";
import "./styles.scss";

function CommonSwitch({ state, setState }) {
  return (
    <div>
      <Switch
        checked={state}
        onClick={() => setState(!state)}
        className="custom-switch"
      />
    </div>
  );
}

export default CommonSwitch;
